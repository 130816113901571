import React, { useRef, useState } from "react";
import VisibileConMonrealeCard from "../UI/VisibileConMonrealeCard";
import Video360Component from "./Video360Component";

const VideoCard = ({
  videoUrl,
  type,
  withFanoCard,
  titolo,
  immagine_anteprima,
}) => {
  const [isFullPage, setIsFullPage] = useState(false);
  const [duration, setDuration] = useState(0);
  const videoRef = useRef(null);
  const handleVideoClick = () => {
    if (isIOS()) {
      setIsFullPage(true); // Apriamo la modalità fullscreen automaticamente su iOS
    } else {
      setIsFullPage((prevIsFullPage) => !prevIsFullPage); // Toggle fullscreen su altri dispositivi
    }
  };

  const handleCloseFullscreen = (e) => {
    e.stopPropagation();
    setIsFullPage(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const previewWidth = window.innerWidth - 50;
  const previewHeight = 200;

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const handleLoadedMetadata = (e) => {
    const videoElement = e.target;
    setDuration(videoElement.duration);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const videoStyle = isFullPage
    ? {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        padding: "12px",
        paddingTop: "20px",
        backgroundColor: "black",
        zIndex: 9999,
      }
    : {
        width: previewWidth + "px",
        maxHeight: previewHeight + "px",
        height: previewHeight + "px",
        cursor: "pointer",
        zIndex: 1,
        display: "flex",
      };

  return (
    <div
      style={videoStyle}
      className={isFullPage ? null : "my-3 relative border border-gray-400"}
      onClick={handleVideoClick}
    >
      {isFullPage && (
        <div
          style={{
            position: "absolute",
            top: "48px",
            left: "24px",
            cursor: "pointer",
            width: "48px",
            height: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#5392AB",
            borderRadius: "50%",
            zIndex: 2,
          }}
          onClick={handleCloseFullscreen}
        >
          <p
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "32px",
            }}
          >
            &#x2715;
          </p>
        </div>
      )}{" "}
      {type === "Video 360" && isFullPage ? (
        <Video360Component videoUrl={videoUrl} />
      ) : (
        <video
          ref={videoRef}
          src={`https://backendstage.monreale.bbsitalia.com/${videoUrl}#t=1`}
          controls={isFullPage}
          autoPlay={isFullPage}
          playsInline
          loop={isFullPage}
          style={{
            objectFit: !isFullPage ? "cover" : "contain",
            backgroundColor: "black",
            width: "100%",
            height: "100%",
          }}
          onLoadedMetadata={handleLoadedMetadata}
          className="hide-controls"
        />
      )}
      {!isFullPage && (
        <div
          className="absolute bottom-0 left-0 w-2/3 bg-monrealeRed text-sm
         text-center p-2"
        >
          {!!titolo && (
            <p className="mb-1 text-white font-semibold">{titolo}</p>
          )}
          <p className=" text-white font-semibold">{`${type} - Durata: ${formatTime(
            duration
          )}`}</p>
        </div>
      )}
    </div>
  );
};

export default VideoCard;
