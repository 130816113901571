import React, { useEffect, useState } from "react";
import "./FormCartaFisica.css";
import { useTranslation } from "react-i18next";

const FormCartaFisica = () => {
  const { t } = useTranslation();

  const [precode, setPrecode] = useState("");
  useEffect(() => {
    const precode = localStorage.getItem("currentCard");
    if (precode) {
      setPrecode(precode);
    }
  }, []);

  const [editing, setEditing] = useState(false);
  const startEditing = () => {
    setEditing(true);
    setShowSuccessCard(false);
    setShowError(false);
  };
  const [formData, setFormData] = useState({
    precode: `${localStorage.getItem("currentCard")}`,
    uuid: `${localStorage.getItem("uuid")}`,
    nome: "",
    cognome: "",
    data: "",
    email: "",
    provenienza: "",
    seriale: 0,
  });

  const [showSuccessCard, setShowSuccessCard] = useState(false);
  const [showError, setShowError] = useState(false);
  const [dataError, setDataError] = useState(false);

  const handleInputChange = (e) => {
    let value =
      e.target.type === "number" ? parseInt(e.target.value) : e.target.value;

    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    const oggi = new Date();
    oggi.setHours(0, 0, 0, 0);

    const data = new Date(formData.data);
    data.setHours(0, 0, 0, 0);
    const timestamp = Math.floor(data.getTime() / 1000); // Divisione per 1000 per ottenere il timestamp in secondi
    // if (formData.data) {
    //     const [day, month, year] = formData.data.split('-');
    //     const inputDate = new Date(year, month - 1, day); // Mese inizia da 0
    //     const today = new Date();
    //
    //     if (isNaN(inputDate.getTime()) || inputDate < today) {
    //         setDataError(true);
    //         return;
    //     }
    // }

    // if (data.getTime() < oggi.getTime()) {
    //     setShowError(true);
    //     return;
    // }

    const formDataWithTimestamp = {
      ...formData,
      data: timestamp.toString(),
    };

    // Chiamata all'API per inviare i dati
    // Puoi sostituire l'URL con l'endpoint corretto della tua API
    const apiUrl =
      "https://dev.fanocard.bbsitalia.com/fanocard/setprecode.json";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataWithTimestamp),
    })
      .then((response) => {
        if (response.ok) {
          return response.json(); // Converti la risposta in JSON
        } else {
          setShowError(true);
          throw new Error(`${t("errorenellachiamataapi")}`);
        }
      })
      .then((data) => {
        if (data.status === false && !data.hasOwnProperty("message")) {
          setShowError(true);
          throw new Error(`${t("rispostaapinonvalida")}`);
        }

        if (data.status === true && data.hasOwnProperty("message")) {
          setShowSuccessCard(true);
          return;
        }

        // Salva la risposta nel localStorage
        const updatedData = {
          ...data,
          precode: precode,
        };
        localStorage.setItem(`qrCode-${precode}`, JSON.stringify(updatedData));

        setShowSuccessCard(true);
      })
      .catch((error) => {
        console.error(`${t("erroredurantelachiamataallapi")}`, error);
        setShowError(true);
      });
  };

  const resetForm = () => {
    setFormData({
      precode: `${localStorage.getItem("currentCard")}`,
      uuid: `${localStorage.getItem("uuid")}`,
      nome: "",
      cognome: "",
      data: "",
      email: "",
      provenienza: "",
      seriale: 0,
    });
    setShowError(false);
    setShowSuccessCard(false);
    window.location.reload();
  };

  const [isMenuOpen2, setIsMenuOpen] = useState(true);
  const [height2, setHeight] = useState("65vh");
  const [top2, setTop] = useState("580px");
  const [padding2, setPadding] = useState("5");
  const [paddingTop2, setPaddingTop] = useState("4");
  const [paddingBottom2, setPaddingBottom] = useState("4");

  const toggleHeight = () => {
    setHeight(height2 === "0" ? "65vh" : "0");
    setTop(top2 === "580px" ? "0" : "580px");
    setPadding(padding2 === "0" ? "5" : "0");
    setPaddingTop(paddingTop2 === "0" ? "4" : "0");
    setPaddingBottom(paddingBottom2 === "0" ? "4" : "0");
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen2);
    toggleHeight();
  };

  return (
    <div
      className={`${
        isMenuOpen2 ? "bg-white " : "bg-fanoBlue delay-300"
      } absolute w-full h-[50vh] top-0 overflow-scroll pb-10  b-t-l-r-15 pl-5 pr-5 z-10`}
    >
      <div
        className={`h-${height2} overflow-auto z-10 relative duration-300 pt-${paddingTop2} pb-${paddingBottom2}`}
      >
        {showSuccessCard ? (
          <div className="bg-green-200 text-green-800 p-4 rounded mb-4">
            {t("cardattivataconsuccesso")}
          </div>
        ) : null}

        {showError ? (
          <div className="bg-red-200 text-red-800 p-4 rounded mb-4">
            {t("erroredurantelattivazionedellacardriprovapiutardi")}
            <button
              onClick={setShowError(false)}
              className="text-red-800 font-semibold underline ml-2"
            >
              {t("modifica")}
            </button>
          </div>
        ) : null}

        {!showSuccessCard && (
          <div className={"overflow-auto"}>
            <h1 className="text-xl font-semibold mb-4 text-left text-fanoBlue">
              {t("inserisciidatiperlafanovisitcard")}
            </h1>

            <form onSubmit={submitForm}>
              <div className="mb-4">
                <label
                  htmlFor="nome"
                  className="block font-medium mb-1 text-left"
                >
                  {t("nome")}
                </label>
                <input
                  type="text"
                  id="nome"
                  name="nome"
                  className="border border-gray-300 rounded px-2 py-1 w-full"
                  value={formData.nome}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="cognome"
                  className="block font-medium mb-1 text-left"
                >
                  {t("cognome")}
                </label>
                <input
                  type="text"
                  id="cognome"
                  name="cognome"
                  className="border border-gray-300 rounded px-2 py-1 w-full"
                  value={formData.cognome}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block font-medium mb-1 text-left"
                >
                  {t("email")}
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="border border-gray-300 rounded px-2 py-1 w-full"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="provenienza"
                  className="block font-medium mb-1 text-left"
                >
                  {t("provenienza")}
                </label>
                <input
                  type="text"
                  id="provenienza"
                  name="provenienza"
                  className="border border-gray-300 rounded px-2 py-1 w-full"
                  value={formData.provenienza}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="data"
                  className="block font-medium mb-1 text-left"
                >
                  {t("datainizio")}
                </label>
                <input
                  type="date"
                  id="data"
                  name="data"
                  className="border border-gray-300 rounded px-2 py-1 w-full"
                  value={formData.data}
                  onChange={handleInputChange}
                  required
                />
                {dataError && (
                  <div className=" mt-2 bg-red-200 text-red-800 p-4 rounded mb-4">
                    {t("ladatanonpuoessereinferiorealgiornocorrente")}
                  </div>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="seriale"
                  className="block font-medium mb-1 text-left"
                >
                  {t("seriale")}
                </label>
                <input
                  type="number"
                  id="seriale"
                  name="seriale"
                  className="border border-gray-300 rounded px-2 py-1 w-full"
                  value={formData.seriale}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="mb-4 flex flex-wrap">
                <label
                  htmlFor="privacy"
                  className="w-full font-semibold block font-medium mb-1 text-left"
                >
                  {t("informativabreve")}
                </label>
                <p className={"w-full mb-5"}>
                  L’attivazione della “Fano Visit Card” comporta il trattamento
                  dei dati personali degli aderenti da parte del Comune di Fano.
                  Tale trattamento è finalizzato alla promozione del territorio
                  e delle attività turistico culturali organizzate nella città
                  di Fano. Le basi giuridiche del trattamento sono l’esecuzione
                  di un contratto di cui l’interessato è parte, oltreché
                  l’adempimento degli obblighi legali a cui è soggetto il
                  titolare del trattamento mentre per l’iscrizione alla
                  newsletter o per altre finalità di promozione (sms, e-mail,
                  messaggistica istantanea, notifiche via app “Visit Fano”, o
                  più tradizionali come ad esempio telefonate con operatore e
                  posta tradizionale per l’invio di newsletter, comunicazioni
                  relative ad eventi e manifestazioni) la base giuridica è il
                  consenso dell’interessato. Nelle attività di trattamento
                  rientrano le finalità contrattuali, amministrative, fiscali e
                  contabili connesse nonché le statistiche di utilizzo della
                  “Fano Visit Card” e la valutazione della qualità dei servizi
                  erogati. La “Fano Visit Card” è gratuita per i vacanzieri o
                  per i visitatori che abbiano correttamente versato la prevista
                  tassa di soggiorno mentre è a pagamento per gli abitanti di
                  Fano; ha un periodo massimo di validità di 14 giorni. Il
                  trattamento dei dati è effettuato con modalità manuali e
                  informatiche. Il conferimento dei dati è obbligatorio per lo
                  svolgimento del procedimento amministrativo e il mancato
                  conferimento ne comporta l’annullamento per impossibilità a
                  realizzare l’istruttoria necessaria. I dati raccolti sono
                  quelli da Voi forniti con il presente modulo e saranno
                  elaborati dal personale del Comune, che agisce sulla base di
                  specifiche istruzioni. I Vostri dati possono essere comunicati
                  all'esterno a soggetti nominati responsabili del trattamento
                  per svolgere determinati servizi. I Vostri dati possono essere
                  comunicati ad altre amministrazioni, così come previsto dalla
                  normativa in vigore. Il dichiarante può esercitare i diritti
                  di cui agli artt. 15 e ss. del Regolamento (UE) 2016/679:{" "}
                  <a
                    className={"text-fanoBlue underline"}
                    href={"https://www.garanteprivacy.it"}
                  >
                    www.garanteprivacy.it
                  </a>
                  . Il Comune è il titolare del trattamento dei dati.
                  L'informativa completa e i dati di contatto del DPO/RPD sono
                  disponibili presso il sito web del Comune o presso gli
                  appositi uffici; ulteriori informazioni su:{" "}
                  <a
                    className={"text-fanoBlue underline"}
                    href={"https://www.comune.fano.pu.it/gdpr-e-privacy"}
                  >
                    www.comune.fano.pu.it/gdpr-e-privacy
                  </a>
                </p>

                <input
                  type="checkbox"
                  id="privacy"
                  name="privacy"
                  className="border border-gray-300 rounded px-2 py-1 w-full basis-1/12 mt-5"
                  // value={formData.seriale}
                  // onChange={handleInputChange}
                  required
                />
                <p className={"inline-block basis-11/12 mb-10"}>
                  Presto il consenso per l’iscrizione alla newsletter e ad altre
                  modalità di promozione degli eventi e delle manifestazioni
                  organizzate dal Comune di Fano
                </p>
              </div>

              <button
                type="submit"
                className="bg-fanoBlue text-white rounded px-4 py-2 hover:bg-blue-600"
              >
                {t("invia")}
              </button>
              <button
                type="button"
                onClick={resetForm}
                className="bg-red-500 text-white rounded px-4 py-2 ml-4 hover:bg-red-600"
              >
                {t("reset")}
              </button>
            </form>
          </div>
        )}
      </div>
      {/*<button className="bg-fanoBlue absolute rounded-full w-12 h-12 flex items-center justify-center right-5 top-[35%] text-white z-10" onClick={toggleMenu}>*/}
      {/*    {isMenuOpen2 ? (*/}
      {/*        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left">*/}
      {/*            <polyline points="15 18 9 12 15 6"></polyline>*/}
      {/*        </svg>*/}
      {/*    ) : (*/}
      {/*        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right">*/}
      {/*            <polyline points="9 18 15 12 9 6"></polyline>*/}
      {/*        </svg>*/}
      {/*    )}*/}
      {/*</button>*/}
      {showSuccessCard && (
        <button
          className="bg-green-500 text-white rounded px-4 py-2 mx-auto block mt-0 w-full h-14"
          onClick={resetForm}
        >
          {t("visualizzaletuecard")}
        </button>
      )}
    </div>
  );
};

export default FormCartaFisica;
