import axios from "axios";
import {useEffect, useState} from "react";
import InEvidenzaCarousel from "./InEvidenzaCarousel";

const InEvidenza = () => {
    return (
        <div className={'h-[59vh]'}>
                <InEvidenzaCarousel/>
        </div>
    )
}

export default InEvidenza