import { useTranslation } from "react-i18next";

export function ShowAllButton({ setFilteredEvents, events }) {
  const showAll = () => {
    setFilteredEvents(events);
  };
  const { t } = useTranslation();

  return (
    <button
      className={
        "font-semibold font-roboto_slab border border-monrealeRed mb-5 h-[70px] w-[70px] rounded-[10px] mt-2 text-monrealeRed"
      }
      onClick={showAll}
    >
      {t("mostratutti")}
    </button>
  );
}
