import React, { useState } from 'react';
import PoadcastInterface from '../components/UI/PoadcastInterface';
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import sfondoTest from '../assets/pexels-mubariz-mammadli-12801948.jpg';

const PodcastPlayer = () => {
    const tracks = [
        {
            title: 'Episodio 1 - Introduzione al Podcast',
            url: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
        },
        {
            title: 'Episodio 2 - Approfondimento sul tema',
            url: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
        },
        // Aggiungi altre tracce qui...
    ];

    const [timeBarPosition, setTimeBarPosition] = useState(0);

    const titles = tracks.map(track => track.title);
    const podcastTitle = titles[0];

    return (
        <div className="min-h-screen bg-gray-200">
            <div className="blur">
            <img src={sfondoTest} alt={'sfondoTest'} className={'object-cover w-full h-screen'}/>
            </div>
            <div
                className="fixed bottom-0 left-0 right-0 z-10 p-4 rounded-t-3xl shadow-md bg-white"
            >
                <div className="flex items-center justify-center">
                    <button
                        className="bg-gray-200 w-8 h-8 p-1 rounded-full"
                    >
                        <ChevronUpIcon className="w-6 h-6 text-gray-600" />
                    </button>
                </div>
                <div className="mt-4">
                    <PoadcastInterface title={podcastTitle} tracks={tracks} />
                </div>
            </div>
            {/*<div className="fixed bottom-0 right-0 z-10 p-4 rounded-full shadow-md bg-white transition-transform duration-500">*/}
            {/*    <button*/}
            {/*        className="w-8 h-8 p-1 rounded-full transform translate-x-0"*/}
            {/*    >*/}
            {/*        <ChevronUpIcon className="w-6 h-6 text-gray-600" />*/}
            {/*    </button>*/}
            {/*    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 transition-opacity duration-500 opacity-100">*/}
            {/*        <div className="relative w-full h-1 bg-gray-300">*/}
            {/*            <div*/}
            {/*                className="absolute top-0 left-0 h-1 bg-blue-500"*/}
            {/*                style={{ width: `${timeBarPosition}%` }}*/}
            {/*            ></div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default PodcastPlayer;
