import sfondoHeaderImage from "../assets/pexels-976866.png";
import { BackButton } from "../components/UI/BackButton";
import SearchBlue from "../assets/icons/searchBlue.svg";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import doveDormireIcon from "../assets/icons/doveDormire.svg";
import doveMangiareIcon from "../assets/icons/doveMangiare.svg";
import shoppingIcon from "../assets/icons/shopping.svg";
import backButtonImage from "../assets/icons/backButtonImage.svg";

export const FanoCardLista = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();
    axios
      .get(
        `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/pagina-sezione/1304`
      )
      .then((response) => setData(response.data[0]))
      .catch((error) => console.log(error));
  }, []);

  const [text, setTest] = useState([]);
  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();
    axios
      .get(`https://agendatreviso.bbsitalia.com/office/1`)
      .then((response) => setTest(response))
      .catch((error) => console.log(error));
  }, []);

  console.log(data);
  console.log(text);

  return (
    <section id={"ospitalitaESapori"}>
      <div
        className={
          "flex flex-wrap h-[28vh] content-center relative flex overflow-hidden"
        }
      >
        <img
          src={sfondoHeaderImage}
          alt={"sfondoHeaderImage"}
          className={"object-cover w-full"}
        />
        <div className="absolute inset-0 bg-black bg-opacity-60 flex justify-start items-center pl-8 pr-8">
          <div className="flex flex-wrap items-start">
            <div className="flex flex-wrap">
              <Link to={"/fano-card"}>
                <button className={""}>
                  <img
                    src={backButtonImage}
                    alt={"backButtonImage"}
                    className={"w-10 h-10"}
                  />
                </button>
              </Link>
            </div>
            <div className="flex flex-col ml-4">
              {data && (
                <h1 className="text-white font-open-sans text-[25px] font-semibold mb-10">
                  {data.titolo}
                </h1>
              )}
            </div>
            {data && (
              <p
                className="text-white text-left"
                dangerouslySetInnerHTML={{ __html: data.body }}
              ></p>
            )}
          </div>
        </div>
      </div>
      {/*<div className={'flex flex-wrap px-8 h-[50px] -mt-[25px] relative'}>*/}
      {/*    <img src={SearchBlue} alt={'SearchBlue'} className={'absolute left-12 top-1/2 transform -translate-y-1/2 z-20'} />*/}
      {/*    <input type={'text'} placeholder={t('cerca')} className={'text-left w-full h-[50px] px-5 pl-14 font-semibold relative z-10 rounded-[10px] shadow-monrealeShadow'}/>*/}
      {/*</div>*/}
      {/*<div className={'flex flex-wrap justify-center items-center mt-10 py-5 px-8'}>*/}
      <div
        className={"flex flex-wrap justify-center items-center mt-2 py-5 px-8"}
      >
        <Link to={"/dove-dormire"} className={"w-full mb-8"}>
          <div
            className={
              "flex flex-wrap justify-start items-center shadow-monrealeShadow px-5 py-10 rounded-[10px]"
            }
          >
            <img src={doveDormireIcon} alt={"placeholder"} className={"mr-8"} />
            <span
              className={
                "text-monrealeRed font-roboto_slab text-[25px] font-bold"
              }
            >
              Video
            </span>
          </div>
        </Link>

        <Link to={"/dove-mangiare"} className={"w-full mb-8"}>
          <div
            className={
              "flex flex-wrap justify-start items-center shadow-monrealeShadow px-5 py-10 rounded-[10px]"
            }
          >
            <img
              src={doveMangiareIcon}
              alt={"placeholder"}
              className={"mr-8"}
            />
            <span
              className={
                "text-monrealeRed font-roboto_slab text-[25px] font-bold"
              }
            >
              3D
            </span>
          </div>
        </Link>

        <Link to={"/poadcast"} className={"w-full"}>
          <div
            className={
              "flex flex-wrap justify-start items-center shadow-monrealeShadow px-5 py-10 rounded-[10px]"
            }
          >
            <img src={shoppingIcon} alt={"placeholder"} className={"mr-8"} />
            <span
              className={
                "text-monrealeRed font-roboto_slab text-[25px] font-bold"
              }
            >
              Poadcast
            </span>
          </div>
        </Link>
      </div>
    </section>
  );
};
