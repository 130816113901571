import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import doveDormireWhite from "../../assets/icons/eventiERassegneWhite.svg";
import calendarioIcon from "../../assets/icons/calendario.svg";
import { useTranslation } from "react-i18next";

export function EventList({ events, currentDate }) {
  const { t } = useTranslation();
  const firstCardRef = useRef(null);

  useEffect(() => {
    if (firstCardRef.current) {
      firstCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [events]);
  return (
    <div className="event-list" id="event-list">
      {events.map((event, index) => (
        <div
          key={event.id}
          className={
            "flex flex-wrap basis-1/2 lg:basis-1/3 rounded-[10px] mb-5"
          }
        >
          <Link to={`/eventi-e-rassegne/${event.id}`} className={"w-full"}>
            <div
              className={
                "shadow-monrealeShadow rounded-[10px] w-full relative h-full pb-5"
              }
              ref={index === 0 ? firstCardRef : null}
            >
              <div
                className={
                  "absolute bg-monrealeRed top-0 right-0 p-2 rounded-[10px]"
                }
              >
                <img src={doveDormireWhite} className={"w-6 h-6"} />
              </div>
              <img
                src={`https://backendstage.monreale.bbsitalia.com${event.immagine_anteprima}`}
                className={"w-full h-44 object-cover rounded-[10px]"}
              />
              <div
                className={
                  "flex flex-wrap justify-start items-center px-4 py-2"
                }
              >
                <h1
                  className={
                    "text-monrealeRed text-[20px] font-roboto_slab font-bold pt-3 pb-3"
                  }
                >
                  {event.titolo}
                </h1>
              </div>
              <div className={"flex flex-wrap  px-4 py-2 text-left"}>
                {event.data_inizio && event.data_fine && (
                  <img src={calendarioIcon} className={"w-5 h-5 mr-2"} />
                )}
                {!!event.attiva_data_singola ? (
                  <div>
                    {/* {new Date(currentDate).toLocaleString("it-IT", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })} */}
                  </div>
                ) : (
                  <>
                    {event.data_inizio && (
                      <span
                        className={
                          "text-black text-[15px] font-open-sans text-left inline-block"
                        }
                      >
                        {new Date(event.data_inizio).toLocaleString("it-IT", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </span>
                    )}
                    {event.data_fine && (
                      <span
                        className={
                          "text-black text-[15px] font-open-sans text-left inline-block ml-1 mr-1"
                        }
                      >
                        {" "}
                        -{" "}
                      </span>
                    )}
                    {event.data_fine && (
                      <span
                        className={
                          "text-black text-[15px] font-open-sans text-left inline-block"
                        }
                      >
                        {new Date(event.data_fine).toLocaleString("it-IT", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </span>
                    )}
                  </>
                )}
              </div>
              <div className={"flex flex-wrap justify-between px-4 py-2"}>
                <p
                  className={"text-black text-[15px] font-open-sans text-left"}
                >
                  {event.testo_corto}
                </p>
                {/*<Link to={`/eventi-e-rassegne/${event.id}`} className={'text-monrealeRed text-[15px] font-roboto_slab font-semibold mt-2'}>{t('scopri')}</Link>*/}
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}
