import React, { useState, useRef, useEffect } from "react";
import styles from "./AudioPlayer.module.css";
import { useTranslation } from "react-i18next";

import playIcon from "../../assets/icons/play.svg";
import pauseIcon from "../../assets/icons/pause.svg";
import forwardIcon from "../../assets/icons/forward.svg";
import backwardIcon from "../../assets/icons/backward.svg";

import "./Audio.css";

const AudioPlayer = (props) => {
  // state
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  // references
  const audioPlayer = useRef(); // reference our audio component
  const progressBar = useRef(); // reference our progress bar
  const animationRef = useRef(); // reference the animation

  useEffect(() => {
    const seconds = Math.floor(audioPlayer.current.duration);
    setDuration(seconds);
    progressBar.current.max = seconds;
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current?.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(progressBar.current.value / duration) * 100 + 1}%`
    );
    setCurrentTime(progressBar.current.value);
  };

  const backThirty = () => {
    const newValue = Number(progressBar.current.value) - 10;
    progressBar.current.value = Math.max(newValue, 0); // Imposta il valore al massimo tra newValue e 0
    changeRange();
  };

  const forwardThirty = () => {
    const newValue = Number(progressBar.current.value) + 10;
    progressBar.current.value = Math.min(newValue, duration); // Imposta il valore al minimo tra newValue e duration
    changeRange();
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [height, setHeight] = useState("0");
  const [top, setTop] = useState("[540px]");
  const [padding, setPadding] = useState("0");
  const [paddingTop, setPaddingTop] = useState("0");
  const [paddingBottom, setPaddingBottom] = useState("0");

  function toggleHeight() {
    setHeight(height === "0" ? "30vh" : "0");
    setTop(top === "[580px]" ? "0" : "[580px]");
    setPadding(padding === "0" ? "5" : "0");
    setPaddingTop(paddingTop === "0" ? "4" : "0");
    setPaddingBottom(paddingBottom === "0" ? "4" : "0");
  }

  const toggleMenu = () => {
    if (!props.openedFromBanner && props.mid_export) {
      if (!!isMenuOpen) props.setSelectedAudio(null);
      else props.setSelectedAudio(props.mid_export);
    }
    setIsMenuOpen(!isMenuOpen);
    toggleHeight();
  };

  useEffect(() => {
    if (!!isMenuOpen) {
      // Avvia l'audio solo se non è già in riproduzione
      if (!isPlaying) {
        audioPlayer.current.play();
        setIsPlaying(true);
        animationRef.current = requestAnimationFrame(whilePlaying);
      }
    } else {
      // Ferma l'audio solo se è in riproduzione
      if (isPlaying) {
        audioPlayer.current.pause();
        setIsPlaying(false);
        cancelAnimationFrame(animationRef.current);
      }
    }
  }, [isMenuOpen]);

  const { t } = useTranslation();

  return (
    <div className={!!props.openedFromBanner ? "absolute z-10" : "relative"}>
      <button
        className={!!props.openedFromBanner ? "w-10 h-10 " : "w-full h-[200px]"}
        onClick={toggleMenu}
      >
        {/*   <p className={"text-monrealeRed font-semibold"}>
          {t("aggiungiunaltracard")}
        </p> */}
        {/*<img src={plusIcon} alt={"Aggiungi Fano Card"} className={"h-6 w-6 mx-auto mb-3"} />*/}
      </button>
      {!props.openedFromBanner && (
        <div
          className="absolute bottom-2 left-0 w-2/3 bg-monrealeRed text-sm
         text-center p-2"
        >
          <p className="text-white font-semibold uppercase">{props.titolo}</p>
          {/*    <p className="text-white font-semibold">{`${t(
          "durata"
        )} - ${calculateTime(duration)}`}</p> */}
        </div>
      )}
      <div
        className={`${
          isMenuOpen ? "" : "hidden"
        } fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full`}
        id="my-modal"
        onClick={toggleMenu}
      ></div>
      <div
        className={`${
          isMenuOpen ? "bg-white " : " delay-300"
        } fixed w-full left-0 bottom-0 overflow-hidden z-50 b-t-l-r-15 `}
        style={{ backgroundColor: "#ffffff" }}
      >
        <div
          className={`h-${height} top-${top} z-10 relative duration-300 pt-${paddingTop} pb-${paddingBottom}`}
        >
          <span className="block text-black font-medium text-l mt-5 mb-12 uppercase font-semibold">
            {props.titolo}
          </span>

          <audio
            ref={audioPlayer}
            src={props.audioUrl}
            preload="metadata"
            onLoadedMetadata={() => {
              const seconds = Math.floor(audioPlayer.current.duration);
              setDuration(seconds);
              progressBar.current.max = seconds;
            }}
          ></audio>
          <div
            className={
              "w-full flex-wrap flex space-x-2 items-center justify-between px-10"
            }
          >
            <button
              className="inline-block align-middle mr-5 "
              onClick={backThirty}
            >
              <img src={backwardIcon} className={"w-9"} />
            </button>
            <button
              className="inline-block align-middle w-12 text-center"
              onClick={togglePlayPause}
            >
              {isPlaying ? (
                <img src={pauseIcon} className={"h-14 m-auto"} />
              ) : (
                <img src={playIcon} className={"h-14 m-auto"} />
              )}
            </button>
            <button
              className="inline-block align-middle ml-5 "
              onClick={forwardThirty}
            >
              <img src={forwardIcon} className={"w-9"} />
            </button>
          </div>

          {/* progress bar */}

          <div
            className={
              "w-full flex-wrap flex space-x-2 items-center justify-between px-10"
            }
          >
            <div className={"flex w-full mt-10 mb-2 pl-2"}>
              <input
                type="range"
                className={styles.progressBar}
                defaultValue="0"
                ref={progressBar}
                onChange={changeRange}
              />
            </div>

            {/* current time */}
            <div className={styles.currentTime}>
              {calculateTime(currentTime)}
            </div>

            {/* duration */}
            <div className={styles.duration}>
              {duration && !isNaN(duration) && calculateTime(duration)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AudioPlayer };
