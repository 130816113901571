import reportWebVitals from "./reportWebVitals";

import { HashRouter } from "react-router-dom";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["it", "en", "es", "fr", "de", "zh-Hans"],
    resources: {
      en: {
        translation: {
          prezzi: "Prices",
          orari: "When",
          telefono: "Telephone",
          email: "Email",
          pec: "PEC",
          sito: "Website",
          impostazioni: "Settings",
          lingua: "Language",
          aroundme: "Around Me",
          ScopriMonreale: "Discover Monreale",
          tutte: "All",
          dovedormire: "Where to sleep",
          dovemangiare: "Where to eat",
          museiecultura: "Museums and culture",
          itinerarituristici: "Tourist itineraries",
          saporieshopping: "Flavors and shopping",
          news: "News",
          rassegna: "Review",
          eventi: "Event",
          puntidiinteresse: "Points of interest",
          cerca: "Search",
          selezionaunacategoria: "Select a category",
          filtrapercategoria: "Filter",
          chiudi: "Close",
          filtra: "Filter",
          cambiolingua1: "Changing the language will restart the app",
          cambiolingua2: "Are you sure you want to change the language?",
          cambiolingua3: "Confirm",
          cambiolingua4: "Cancel",
          contattaci: "Contact us",
          testocontattaci1:
            "The contents have been created and coordinated with the images by the press and communication office of Monreale",
          testocontattaci2: "Call & Go",
          testocontattaci3: "Call us",
          testocontattaci4: "The photos are taken by Giuseppe Giurintano",
          testocontattaci5: "The team Visit Monreale",
          crediti: "Credits",
          crediti1: "Software design and implementation: ",
          vedieventi: "See the events of the review",
          vediluoghi: "See the places of the itinerary",
          portami: "Take me there",
          calendario: "Calendar",
          evidenza: "Highlights",
          nessunrisultatotrovatoattornoate: "No results found around you",
          vaiallascheda: "Go to the card",
          mostratutti: "Show all",
          nessunrisultatotrovato: "No results found",
          noncisonoeventiperilgiornoselezionato:
            "There are no events for the selected day",
          stabilimentibalneari: "Beach establishments",
          mesi: "Months",
          filtraperdata: "Filter by date",
          datainizio: "Start date",
          datafine: "End date",
          applicafiltro: "Apply filter",
          Gennaio: "January",
          Febbraio: "February",
          Marzo: "March",
          Aprile: "April",
          Maggio: "May",
          Giugno: "June",
          Luglio: "July",
          Agosto: "August",
          Settembre: "September",
          Ottobre: "October",
          Novembre: "November",
          Dicembre: "December",
          lunedi: "Monday",
          martedi: "Tuesday",
          mercoledi: "Wednesday",
          giovedi: "Thursday",
          venerdi: "Friday",
          sabato: "Saturday",
          domenica: "Sunday",
          lunedip: "Mon",
          martedip: "Tue",
          mercoledip: "Wed",
          giovedip: "Thu",
          venerdip: "Fri",
          sabatop: "Sat",
          domenicap: "Sun",
          inserisciilcodicedellacard: "Enter the code of the card",
          cartanonvalida: "Invalid card",
          lacartaegiaattivasuunaltrotelefonononpuoiattivarlasuquestotelefono:
            "The card is already active on another phone, you cannot activate it on this phone",
          erroresconosciutodurantelaverificadellacarta:
            "Unknown error during card verification",
          erroredurantelaverificadellacarta: "Error during card verification",
          aggiungiunaltracard: "Add another card",
          acquista: "Buy",
          aggiungilatuacfanovisitcard: "Add your Fano Visit Card",
          attivalafanovisitcard: "Activate the Fano Visit Card",
          inserisciilcodicechehairicevuto: "Enter the code you received",
          scannerizzailqrcode: "Scan the QR code",
          scansiona: "Scan",
          invia: "Send",
          errorenellachiamataapi: "Error in the API call",
          rispostaapinonvalida: "Invalid API response",
          erroredurantelachiamataallapi: "Error during the call to the API",
          cardattivataconsuccesso: "Card activated successfully",
          erroredurantelattivazionedellacardriprovapiutardi:
            "Error during card activation, try again later",
          modifica: "Edit",
          inserisciidatiperlafanovisitcard:
            "Enter the data for the Fano Visit Card",
          nome: "Name",
          cognome: "Surname",
          provenienza: "City of origin",
          ladatanonpuoessereinferiorealgiornocorrente:
            "The date cannot be earlier than the current day",
          seriale: "Progessive number (Numero progressivo)",
          informativabreve: "Short information",
          reset: "Reset",
          visualizzaletuecard: "View your cards",
          inserisciilcodice: "Enter the code",
          oggi: "Today",
          siattiveratra: "It will be activated on",
          giorni: "days",
          seisicurodivolereliminarequestacarta:
            "Are you sure you want to delete this card?",
          attivo: "Active",
          nonattivo: "Not active",
          fanovisitcard: "Fano Visit Card",
          elimina: "Delete",
          serviziinclusicart: "Services included",
          giornirimanenti: "Remaining days",
          letuefanovisitcard: "Your Fano Visit Card",
          scadetra: "It will expire on:",
          giorniscade: "Days",
          scopridipiu: "Discover more",
          acquistafanocard: "Buy your Fano Card",
          sbloccaContenuto:
            "Unlock the content by activating or purchasing your Fano Visit Card",
          durata: "Duration",
          sceglilatipologia: "Choose the type",
          nessunrisultatocorrispondente: "No results matching your search",
          chiudicamera: "Close camera",
          prodottitipici: "Typical products",
          viniecantine: "Wines and wineries",
          numeriUtili: "Useful numbers",
          comeArrivare: "How to get there",
          comuneeterritorio: "Municipality and territory",
          changeLanguageConfirmationTitle:
            "Changing the language will restart the app",
          changeLanguageConfirmationText:
            "Are you sure you want to change the language?",
          changeLanguageConfirmationButton: "Confirm",
          changeLanguageConfirmationCancelButton: "Cancel",
          language: "Language",
        },
      },
      it: {
        translation: {
          prezzi: "Prezzi",
          orari: "Orari",
          telefono: "Telefono",
          email: "Email",
          pec: "PEC",
          sito: "Sito Web",
          impostazioni: "Impostazioni",
          lingua: "Lingua",
          evidenza: "Evidenza",
          aroundme: "Around Me",
          ScopriMonreale: "Scopri Monreale",
          tutte: "Tutte",
          dovedormire: "Dove dormire",
          dovemangiare: "Dove mangiare",
          museiecultura: "Musei e cultura",
          itinerarituristici: "Itinerari turistici",
          saporieshopping: "Sapori e shopping",
          news: "News",
          rassegna: "Rassegna",
          eventi: "Eventi",
          puntidiinteresse: "Punti di interesse",
          cerca: "Cerca",
          selezionaunacategoria: "Seleziona una categoria",
          filtrapercategoria: "Filtra per categoria",
          chiudi: "Chiudi",
          filtra: "Filtra",
          cambiolingua1: "Cambiando la lingua l'app verrá riavviata",
          cambiolingua2: "Sei sicuro di voler cambiare la lingua?",
          cambiolingua3: "Conferma",
          cambiolingua4: "Annulla",
          contattaci: "Contattaci",
          testocontattaci1:
            "I contenuti sono stati realizzati e coordinati con le immagini  dall'ufficio stampa e comunicazione di Monreale",
          testocontattaci2: "Call & Go",
          testocontattaci3: "Chiamaci",
          testocontattaci4: "Le foto sono realizzate da Giurintano Giuseppe",
          testocontattaci5: "Il team Visit Monreale",
          crediti: "Crediti",
          crediti1: "Progettazione e realizzazione software: ",
          vedieventi: "Vedi gli eventi della rassegna",
          vediluoghi: "Vedi i luoghi dell'itinerario",
          portami: "Portami",
          calendario: "Calendario",
          nessunrisultatotrovatoattornoate:
            "Nessun risultato trovato attorno a te",
          vaiallascheda: "Vai alla scheda",
          mostratutti: "Mostra tutti",
          nessunrisultatotrovato: "Nessun risultato trovato",
          noncisonoeventiperilgiornoselezionato:
            "Non ci sono eventi per il giorno selezionato",
          stabilimentibalneari: "Stabilimenti balneari",
          mesi: "Mesi",
          filtraperdata: "Filtra per data",
          datainizio: "Data inizio",
          datafine: "Data fine",
          applicafiltro: "Applica filtro",
          Gennaio: "Gennaio",
          Febbraio: "Febbraio",
          Marzo: "Marzo",
          Aprile: "Aprile",
          Maggio: "Maggio",
          Giugno: "Giugno",
          Luglio: "Luglio",
          Agosto: "Agosto",
          Settembre: "Settembre",
          Ottobre: "Ottobre",
          Novembre: "Novembre",
          Dicembre: "Dicembre",
          lunedi: "Lunedí",
          martedi: "Martedí",
          mercoledi: "Mercoledí",
          giovedi: "Giovedí",
          venerdi: "Venerdí",
          sabato: "Sabato",
          domenica: "Domenica",
          lunedip: "Lun",
          martedip: "Mar",
          mercoledip: "Mer",
          giovedip: "Gio",
          venerdip: "Ven",
          sabatop: "Sab",
          domenicap: "Dom",
          inserisciilcodicedellacard: "Inserisci il codice della card",
          cartanonvalida: "Carta non valida",
          lacartaegiaattivasuunaltrotelefonononpuoiattivarlasuquestotelefono:
            "La carta è già attiva su un altro telefono, non puoi attivarla su questo telefono",
          erroresconosciutodurantelaverificadellacarta:
            "Errore sconosciuto durante la verifica della carta",
          erroredurantelaverificadellacarta:
            "Errore durante la verifica della carta",
          aggiungiunaltracard: "Aggiungi un'altra card",
          acquista: "Acquista",
          aggiungilatuacfanovisitcard: "Aggiungi la tua Fano Visit Card",
          attivalafanovisitcard: "Attiva la Fano Visit Card",
          inserisciilcodicechehairicevuto:
            "Inserisci il codice di attivazione che hai ricevuto",
          scannerizzailqrcode: "Scannerizza il QR Code",
          scansiona: "Scansiona",
          invia: "Invia",
          errorenellachiamataapi: "Errore nella chiamata api",
          rispostaapinonvalida: "Risposta api non valida",
          erroredurantelachiamataallapi: "Errore durante la chiamata all'api",
          cardattivataconsuccesso: "Card attivata con successo",
          erroredurantelattivazionedellacardriprovapiutardi:
            "Errore durante l'attivazione della card, riprova più tardi",
          modifica: "Modifica",
          inserisciidatiperlafanovisitcard:
            "Inserisci i dati per la Fano Visit Card",
          nome: "Nome",
          cognome: "Cognome",
          provenienza: "Provenienza",
          ladatanonpuoessereinferiorealgiornocorrente:
            "La data non può essere inferiore al giorno corrente",
          seriale: "Numero progressivo",
          informativabreve: "Informativa breve",
          reset: "Reset",
          visualizzaletuecard: "Visualizza le tue card",
          inserisciilcodice: "Inserisci il codice di attivazione",
          oggi: "Oggi",
          siattiveratra: "Si attiverà tra",
          giorni: "giorni",
          seisicurodivolereliminarequestacarta:
            "Sei sicuro di voler eliminare questa carta?",
          attivo: "Attivo",
          nonattivo: "Non attivo",
          fanovisitcard: "Fano Visit Card",
          elimina: "Elimina",
          serviziinclusicart: "Servizi inclusi",
          giornirimanenti: "Giorni rimanenti",
          letuefanovisitcard: "Le tue Fano Visit Card",
          scadetra: "Scade tra:",
          giorniscade: "Giorni",
          scopridipiu: "Scopri di più",
          acquistafanocard: "Acquista la tua Fano Card",
          sbloccaContenuto:
            "Sblocca il contenuto attivando o acquistando la tua Fano Visit Card",
          durata: "Durata",
          sceglilatipologia: "Scegli la tipologia",
          nessunrisultatocorrispondente:
            "Nessun risultato corrispondente alla tua ricerca",
          chiudicamera: "Chiudi camera",
          prodottitipici: "Prodotti tipici",
          viniecantine: "Vini e cantine",
          numeriUtili: "Numeri utili",
          comeArrivare: "Come arrivare",
          comuneeterritorio: "Comune e territorio",
          changeLanguageConfirmationTitle:
            "Cambiando la lingua l'app verrá riavviata",
          changeLanguageConfirmationText:
            "Sei sicuro di voler cambiare la lingua?",
          changeLanguageConfirmationButton: "Conferma",
          changeLanguageConfirmationCancelButton: "Annulla",
          language: "Lingua",
        },
      },
      es: {
        translation: {
          prezzi: "Precios",
          orari: "Horarios",
          telefono: "Teléfono",
          email: "Correo electrónico",
          pec: "PEC",
          sito: "Sitio web",
          impostazioni: "Ajustes",
          lingua: "Idioma",
          evidenza: "Destacados",
          aroundme: "A mi alrededor",
          ScopriMonreale: "Descubre Monreale",
          tutte: "Todas",
          dovedormire: "Dónde dormir",
          dovemangiare: "Dónde comer",
          museiecultura: "Museos y cultura",
          itinerarituristici: "Itinerarios turísticos",
          saporieshopping: "Sabores y compras",
          news: "Noticias",
          rassegna: "Revisión",
          eventi: "Eventos",
          puntidiinteresse: "Puntos de interés",
          cerca: "Buscar",
          selezionaunacategoria: "Selecciona una categoría",
          filtrapercategoria: "Filtrar por categoría",
          chiudi: "Cerrar",
          filtra: "Filtrar",
          cambiolingua1: "Cambiar el idioma reiniciará la aplicación",
          cambiolingua2: "¿Estás seguro de que quieres cambiar el idioma?",
          cambiolingua3: "Confirmar",
          cambiolingua4: "Cancelar",
          contattaci: "Contáctenos",
          testocontattaci1:
            "Los contenidos han sido realizados y coordinados con las imágenes por la oficina de prensa y comunicación de Monreale",
          testocontattaci2: "Call & Go",
          testocontattaci3: "Llámanos",
          testocontattaci4: "Las fotos son de Giuseppe Giurintano",
          testocontattaci5: "El equipo Visit Monreale",
          crediti: "Créditos",
          crediti1: "Diseño y desarrollo de software: ",
          vedieventi: "Ver los eventos de la revisión",
          vediluoghi: "Ver los lugares del itinerario",
          portami: "Llévame",
          calendario: "Calendario",
          nessunrisultatotrovatoattornoate:
            "No se encontraron resultados a tu alrededor",
          vaiallascheda: "Ir a la tarjeta",
          mostratutti: "Mostrar todo",
          nessunrisultatotrovato: "No se encontraron resultados",
          noncisonoeventiperilgiornoselezionato:
            "No hay eventos para el día seleccionado",
          stabilimentibalneari: "Establecimientos de playa",
          mesi: "Meses",
          filtraperdata: "Filtrar por fecha",
          datainizio: "Fecha de inicio",
          datafine: "Fecha de finalización",
          applicafiltro: "Aplicar filtro",
          Gennaio: "Enero",
          Febbraio: "Febrero",
          Marzo: "Marzo",
          Aprile: "Abril",
          Maggio: "Mayo",
          Giugno: "Junio",
          Luglio: "Julio",
          Agosto: "Agosto",
          Settembre: "Septiembre",
          Ottobre: "Octubre",
          Novembre: "Noviembre",
          Dicembre: "Diciembre",
          lunedi: "Lunes",
          martedi: "Martes",
          mercoledi: "Miércoles",
          giovedi: "Jueves",
          venerdi: "Viernes",
          sabato: "Sábado",
          domenica: "Domingo",
          lunedip: "Lun",
          martedip: "Mar",
          mercoledip: "Mié",
          giovedip: "Jue",
          venerdip: "Vie",
          sabatop: "Sáb",
          domenicap: "Dom",
          inserisciilcodicedellacard: "Introduce el código de la tarjeta",
          cartanonvalida: "Tarjeta no válida",
          lacartaegiaattivasuunaltrotelefonononpuoiattivarlasuquestotelefono:
            "La tarjeta ya está activa en otro teléfono, no puedes activarla en este teléfono",
          erroresconosciutodurantelaverificadellacarta:
            "Error desconocido durante la verificación de la tarjeta",
          erroredurantelaverificadellacarta:
            "Error durante la verificación de la tarjeta",
          aggiungiunaltracard: "Añadir otra tarjeta",
          acquista: "Comprar",
          aggiungilatuacfanovisitcard: "Añade tu Fano Visit Card",
          attivalafanovisitcard: "Activa la Fano Visit Card",
          inserisciilcodicechehairicevuto:
            "Introduce el código de activación que has recibido",
          scannerizzailqrcode: "Escanear el código QR",
          scansiona: "Escanear",
          invia: "Enviar",
          errorenellachiamataapi: "Error en la llamada api",
          rispostaapinonvalida: "Respuesta api no válida",
          erroredurantelachiamataallapi: "Error durante la llamada al api",
          cardattivataconsuccesso: "Tarjeta activada con éxito",
          erroredurantelattivazionedellacardriprovapiutardi:
            "Error durante la activación de la tarjeta, inténtalo de nuevo más tarde",
          modifica: "Editar",
          inserisciidatiperlafanovisitcard:
            "Introduce los datos para la Fano Visit Card",
          nome: "Nombre",
          cognome: "Apellido",
          provenienza: "Procedencia",
          ladatanonpuoessereinferiorealgiornocorrente:
            "La fecha no puede ser anterior al día actual",
          seriale: "Número progresivo",
          informativabreve: "Información breve",
          reset: "Reiniciar",
          visualizzaletuecard: "Ver tus tarjetas",
          inserisciilcodice: "Introduce el código de activación",
          oggi: "Hoy",
          siattiveratra: "Se activará en",
          giorni: "días",
          seisicurodivolereliminarequestacarta:
            "¿Estás seguro de que quieres eliminar esta tarjeta?",
          attivo: "Activo",
          nonattivo: "No activo",
          fanovisitcard: "Fano Visit Card",
          elimina: "Eliminar",
          serviziinclusicart: "Servicios incluidos",
          giornirimanenti: "Días restantes",
          letuefanovisitcard: "Tus Fano Visit Card",
          scadetra: "Caduca el:",
          giorniscade: "Días",
          scopridipiu: "Descubre más",
          acquistafanocard: "Compra tu Fano Card",
          sbloccaContenuto:
            "Desbloquea el contenido activando o comprando tu Fano Visit Card",
          durata: "Duración",
          sceglilatipologia: "Elige el tipo",
          nessunrisultatocorrispondente:
            "No hay resultados que coincidan con tu búsqueda",
          chiudicamera: "Cerrar cámara",
          prodottitipici: "Productos típicos",
          viniecantine: "Vinos y bodegas",
          numeriUtili: "Números útiles",
          comeArrivare: "Cómo llegar",
          comuneeterritorio: "Municipio y territorio",
          changeLanguageConfirmationTitle:
            "Cambiar el idioma reiniciará la aplicación",
          changeLanguageConfirmationText:
            "¿Estás seguro de que quieres cambiar el idioma?",
          changeLanguageConfirmationButton: "Confirmar",
          changeLanguageConfirmationCancelButton: "Cancelar",
          language: "Idioma",
        },
      },
      fr: {
        translation: {
          prezzi: "Prix",
          orari: "Horaires",
          telefono: "Téléphone",
          email: "E-mail",
          pec: "PEC",
          sito: "Site Web",
          impostazioni: "Paramètres",
          lingua: "Langue",
          evidenza: "En vedette",
          aroundme: "Autour de Moi",
          ScopriMonreale: "Découvrir Monreale",
          tutte: "Toutes",
          dovedormire: "Où dormir",
          dovemangiare: "Où manger",
          museiecultura: "Musées et culture",
          itinerarituristici: "Itinéraires touristiques",
          saporieshopping: "Saveurs et shopping",
          news: "Actualités",
          rassegna: "Récapitulatif",
          eventi: "Événements",
          puntidiinteresse: "Points d'intérêt",
          cerca: "Chercher",
          selezionaunacategoria: "Sélectionnez une catégorie",
          filtrapercategoria: "Filtrer par catégorie",
          chiudi: "Fermer",
          filtra: "Filtrer",
          cambiolingua1: "Changer de langue redémarrera l'application",
          cambiolingua2: "Êtes-vous sûr de vouloir changer de langue ?",
          cambiolingua3: "Confirmer",
          cambiolingua4: "Annuler",
          contattaci: "Nous contacter",
          testocontattaci1:
            "Les contenus ont été réalisés et coordonnés avec les images par le bureau de presse et de communication de Monreale",
          testocontattaci2: "Call & Go",
          testocontattaci3: "Appelez-nous",
          testocontattaci4: "Les photos sont de Giuseppe Giurintano",
          testocontattaci5: "L'équipe de Visit Monreale",
          crediti: "Crédits",
          crediti1: "Conception et développement logiciel : ",
          vedieventi: "Voir les événements de la revue",
          vediluoghi: "Voir les lieux de l'itinéraire",
          portami: "Emmène-moi",
          calendario: "Calendrier",
          nessunrisultatotrovatoattornoate:
            "Aucun résultat trouvé autour de vous",
          vaiallascheda: "Aller à la fiche",
          mostratutti: "Tout afficher",
          nessunrisultatotrovato: "Aucun résultat trouvé",
          noncisonoeventiperilgiornoselezionato:
            "Il n'y a pas d'événements pour le jour sélectionné",
          stabilimentibalneari: "Établissements balnéaires",
          mesi: "Mois",
          filtraperdata: "Filtrer par date",
          datainizio: "Date de début",
          datafine: "Date de fin",
          applicafiltro: "Appliquer le filtre",
          Gennaio: "Janvier",
          Febbraio: "Février",
          Marzo: "Mars",
          Aprile: "Avril",
          Maggio: "Mai",
          Giugno: "Juin",
          Luglio: "Juillet",
          Agosto: "Août",
          Settembre: "Septembre",
          Ottobre: "Octobre",
          Novembre: "Novembre",
          Dicembre: "Décembre",
          lunedi: "Lundi",
          martedi: "Mardi",
          mercoledi: "Mercredi",
          giovedi: "Jeudi",
          venerdi: "Vendredi",
          sabato: "Samedi",
          domenica: "Dimanche",
          lunedip: "Lun",
          martedip: "Mar",
          mercoledip: "Mer",
          giovedip: "Jeu",
          venerdip: "Ven",
          sabatop: "Sam",
          domenicap: "Dim",
          inserisciilcodicedellacard: "Entrez le code de la carte",
          cartanonvalida: "Carte non valide",
          lacartaegiaattivasuunaltrotelefonononpuoiattivarlasuquestotelefono:
            "La carte est déjà activée sur un autre téléphone, vous ne pouvez pas l'activer sur ce téléphone",
          erroresconosciutodurantelaverificadellacarta:
            "Erreur inconnue lors de la vérification de la carte",
          erroredurantelaverificadellacarta:
            "Erreur lors de la vérification de la carte",
          aggiungiunaltracard: "Ajouter une autre carte",
          acquista: "Acheter",
          aggiungilatuacfanovisitcard: "Ajoutez votre Fano Visit Card",
          attivalafanovisitcard: "Activer la Fano Visit Card",
          inserisciilcodicechehairicevuto:
            "Entrez le code d'activation que vous avez reçu",
          scannerizzailqrcode: "Scanner le QR Code",
          scansiona: "Scanner",
          invia: "Envoyer",
          errorenellachiamataapi: "Erreur dans l'appel API",
          rispostaapinonvalida: "Réponse API invalide",
          erroredurantelachiamataallapi: "Erreur lors de l'appel à l'API",
          cardattivataconsuccesso: "Carte activée avec succès",
          erroredurantelattivazionedellacardriprovapiutardi:
            "Erreur lors de l'activation de la carte, veuillez réessayer plus tard",
          modifica: "Modifier",
          inserisciidatiperlafanovisitcard:
            "Entrez les données pour la Fano Visit Card",
          nome: "Nom",
          cognome: "Prénom",
          provenienza: "Provenance",
          ladatanonpuoessereinferiorealgiornocorrente:
            "La date ne peut être antérieure au jour actuel",
          seriale: "Numéro de série",
          informativabreve: "Avis de confidentialité",
          reset: "Réinitialiser",
          visualizzaletuecard: "Voir vos cartes",
          inserisciilcodice: "Entrez le code d'activation",
          oggi: "Aujourd'hui",
          siattiveratra: "S'activera dans",
          giorni: "jours",
          seisicurodivolereliminarequestacarta:
            "Êtes-vous sûr de vouloir supprimer cette carte ?",
          attivo: "Actif",
          nonattivo: "Inactif",
          fanovisitcard: "Fano Visit Card",
          elimina: "Supprimer",
          serviziinclusicart: "Services inclus",
          giornirimanenti: "Jours restants",
          letuefanovisitcard: "Vos Fano Visit Cards",
          scadetra: "Expire dans :",
          giorniscade: "Jours",
          scopridipiu: "En savoir plus",
          acquistafanocard: "Achetez votre Fano Card",
          sbloccaContenuto:
            "Débloquez le contenu en activant ou en achetant votre Fano Visit Card",
          durata: "Durée",
          sceglilatipologia: "Choisissez le type",
          nessunrisultatocorrispondente:
            "Aucun résultat correspondant à votre recherche",
          chiudicamera: "Fermer la caméra",
          prodottitipici: "Produits typiques",
          viniecantine: "Vins et caves",
          numeriUtili: "Numéros utiles",
          comeArrivare: "Comment arriver",
          comuneeterritorio: "Commune et territoire",
          changeLanguageConfirmationTitle:
            "Changer de langue redémarrera l'application",
          changeLanguageConfirmationText:
            "Êtes-vous sûr de vouloir changer de langue ?",
          changeLanguageConfirmationButton: "Confirmer",
          changeLanguageConfirmationCancelButton: "Annuler",
          language: "Langue",
        },
      },
      de: {
        translation: {
          prezzi: "Preise",
          orari: "Öffnungszeiten",
          telefono: "Telefon",
          email: "E-Mail",
          pec: "PEC",
          sito: "Webseite",
          impostazioni: "Einstellungen",
          lingua: "Sprache",
          evidenza: "Hervorhebung",
          aroundme: "Um Mich Herum",
          ScopriMonreale: "Entdecke Monreale",
          tutte: "Alle",
          dovedormire: "Übernachtungsmöglichkeiten",
          dovemangiare: "Essgelegenheiten",
          museiecultura: "Museen und Kultur",
          itinerarituristici: "Touristische Routen",
          saporieshopping: "Geschmack und Shopping",
          news: "Neuigkeiten",
          rassegna: "Übersicht",
          eventi: "Veranstaltungen",
          puntidiinteresse: "Sehenswürdigkeiten",
          cerca: "Suche",
          selezionaunacategoria: "Wähle eine Kategorie",
          filtrapercategoria: "Nach Kategorie filtern",
          chiudi: "Schließen",
          filtra: "Filter",
          cambiolingua1: "Das Ändern der Sprache wird die App neu starten",
          cambiolingua2:
            "Sind Sie sicher, dass Sie die Sprache ändern möchten?",
          cambiolingua3: "Bestätigen",
          cambiolingua4: "Abbrechen",
          contattaci: "Kontaktiere uns",
          testocontattaci1:
            "Die Inhalte wurden vom Presse- und Kommunikationsbüro von Monreale erstellt und koordiniert",
          testocontattaci2: "Call & Go",
          testocontattaci3: "Rufen Sie uns an",
          testocontattaci4: "Die Fotos stammen von Giuseppe Giurintano",
          testocontattaci5: "Das Team Visit Monreale",
          crediti: "Credits",
          crediti1: "Softwareentwurf und -entwicklung: ",
          vedieventi: "Sieh dir die Veranstaltungen der Reihe an",
          vediluoghi: "Sieh dir die Orte der Route an",
          portami: "Bring mich hin",
          calendario: "Kalender",
          nessunrisultatotrovatoattornoate:
            "Keine Ergebnisse in deiner Nähe gefunden",
          vaiallascheda: "Gehe zur Karte",
          mostratutti: "Alle anzeigen",
          nessunrisultatotrovato: "Keine Ergebnisse gefunden",
          noncisonoeventiperilgiornoselezionato:
            "Keine Ereignisse für den ausgewählten Tag",
          stabilimentibalneari: "Badeanstalten",
          mesi: "Monate",
          filtraperdata: "Nach Datum filtern",
          datainizio: "Startdatum",
          datafine: "Enddatum",
          applicafiltro: "Filter anwenden",
          Gennaio: "Januar",
          Febbraio: "Februar",
          Marzo: "März",
          Aprile: "April",
          Maggio: "Mai",
          Giugno: "Juni",
          Luglio: "Juli",
          Agosto: "August",
          Settembre: "September",
          Ottobre: "Oktober",
          Novembre: "November",
          Dicembre: "Dezember",
          lunedi: "Montag",
          martedi: "Dienstag",
          mercoledi: "Mittwoch",
          giovedi: "Donnerstag",
          venerdi: "Freitag",
          sabato: "Samstag",
          domenica: "Sonntag",
          lunedip: "Mo",
          martedip: "Di",
          mercoledip: "Mi",
          giovedip: "Do",
          venerdip: "Fr",
          sabatop: "Sa",
          domenicap: "So",
          inserisciilcodicedellacard: "Gib den Kartenkode ein",
          cartanonvalida: "Ungültige Karte",
          lacartaegiaattivasuunaltrotelefonononpuoiattivarlasuquestotelefono:
            "Die Karte ist bereits auf einem anderen Telefon aktiviert, du kannst sie nicht auf diesem Telefon aktivieren",
          erroresconosciutodurantelaverificadellacarta:
            "Unbekannter Fehler bei der Kartenprüfung",
          erroredurantelaverificadellacarta: "Fehler bei der Kartenprüfung",
          aggiungiunaltracard: "Eine weitere Karte hinzufügen",
          acquista: "Kaufen",
          aggiungilatuacfanovisitcard: "Füge deine Fano Visit Card hinzu",
          attivalafanovisitcard: "Aktiviere die Fano Visit Card",
          inserisciilcodicechehairicevuto:
            "Gib den Aktivierungscode ein, den du erhalten hast",
          scannerizzailqrcode: "Scanne den QR-Code",
          scansiona: "Scannen",
          invia: "Senden",
          errorenellachiamataapi: "Fehler beim API-Aufruf",
          rispostaapinonvalida: "Ungültige API-Antwort",
          erroredurantelachiamataallapi: "Fehler beim API-Aufruf",
          cardattivataconsuccesso: "Karte erfolgreich aktiviert",
          erroredurantelattivazionedellacardriprovapiutardi:
            "Fehler bei der Aktivierung der Karte, bitte später erneut versuchen",
          modifica: "Bearbeiten",
          inserisciidatiperlafanovisitcard:
            "Gib die Daten für die Fano Visit Card ein",
          nome: "Name",
          cognome: "Nachname",
          provenienza: "Herkunft",
          ladatanonpuoessereinferiorealgiornocorrente:
            "Das Datum kann nicht vor dem heutigen Tag liegen",
          seriale: "Seriennummer",
          informativabreve: "Kurzinformation",
          reset: "Zurücksetzen",
          visualizzaletuecard: "Zeige deine Karten",
          inserisciilcodice: "Gib den Aktivierungscode ein",
          oggi: "Heute",
          siattiveratra: "Wird aktiviert in",
          giorni: "Tagen",
          seisicurodivolereliminarequestacarta:
            "Bist du sicher, dass du diese Karte löschen möchtest?",
          attivo: "Aktiv",
          nonattivo: "Inaktiv",
          fanovisitcard: "Fano Visit Card",
          elimina: "Löschen",
          serviziinclusicart: "Inkludierte Dienste",
          giornirimanenti: "Verbleibende Tage",
          letuefanovisitcard: "Deine Fano Visit Cards",
          scadetra: "Läuft ab in:",
          giorniscade: "Tage",
          scopridipiu: "Mehr erfahren",
          acquistafanocard: "Kaufe deine Fano Card",
          sbloccaContenuto:
            "Inhalte freischalten, indem du deine Fano Visit Card aktivierst oder kaufst",
          durata: "Dauer",
          sceglilatipologia: "Wähle den Typ",
          nessunrisultatocorrispondente: "Keine Ergebnisse für deine Suche",
          chiudicamera: "Kamera schließen",
          prodottitipici: "Typische Produkte",
          viniecantine: "Weine und Weinkellereien",
          numeriUtili: "Nützliche Nummern",
          comeArrivare: "Anreise",
          comuneeterritorio: "Gemeinde und Gebiet",
          changeLanguageConfirmationTitle:
            "Das Ändern der Sprache wird die App neu starten",
          changeLanguageConfirmationText:
            "Bist du sicher, dass du die Sprache ändern möchtest?",
          changeLanguageConfirmationButton: "Bestätigen",
          changeLanguageConfirmationCancelButton: "Abbrechen",
          language: "Sprache",
        },
      },
      "zh-Hans": {
        translation: {
          prezzi: "价格",
          orari: "时间",
          telefono: "电话",
          email: "电子邮件",
          pec: "PEC",
          sito: "网站",
          impostazioni: "设置",
          lingua: "语言",
          evidenza: "亮点",
          aroundme: "周围",
          ScopriMonreale: "发现蒙雷阿莱",
          tutte: "所有",
          dovedormire: "住宿",
          dovemangiare: "餐厅",
          museiecultura: "博物馆和文化",
          itinerarituristici: "旅游路线",
          saporieshopping: "美食和购物",
          news: "新闻",
          rassegna: "评论",
          eventi: "事件",
          puntidiinteresse: "兴趣点",
          cerca: "搜索",
          selezionaunacategoria: "选择一个类别",
          filtrapercategoria: "按类别筛选",
          chiudi: "关闭",
          filtra: "过滤",
          cambiolingua1: "更改语言将重新启动应用程序",
          cambiolingua2: "您确定要更改语言吗？",
          cambiolingua3: "确认",
          cambiolingua4: "取消",
          contattaci: "联系我们",
          testocontattaci1: "内容由Monreale的新闻和传播办公室制作和协调",
          testocontattaci2: "Call & Go",
          testocontattaci3: "给我们打电话",
          testocontattaci4: "如果您想通过电子邮件联系我们，可以发送至",
          testocontattaci5: "Visit Monreale团队",
          crediti: "信用",
          crediti1: "软件设计和开发：",
          vedieventi: "查看评论的事件",
          vediluoghi: "查看行程的地点",
          portami: "带我去",
          calendario: "日历",
          nessunrisultatotrovatoattornoate: "找不到您周围的结果",
          vaiallascheda: "转到卡",
          mostratutti: "显示全部",
          nessunrisultatotrovato: "找不到结果",
          noncisonoeventiperilgiornoselezionato: "所选日期没有事件",
          stabilimentibalneari: "海滩设施",
          mesi: "月",
          filtraperdata: "按日期筛选",
          datainizio: "开始日期",
          datafine: "结束日期",
          applicafiltro: "应用过滤器",
          Gennaio: "一月",
          Febbraio: "二月",
          Marzo: "三月",
          Aprile: "四月",
          Maggio: "五月",
          Giugno: "六月",
          Luglio: "七月",
          Agosto: "八月",
          Settembre: "九月",
          Ottobre: "十月",
          Novembre: "十一月",
          Dicembre: "十二月",
          lunedi: "星期一",
          martedi: "星期二",
          mercoledi: "星期三",
          giovedi: "星期四",
          venerdi: "星期五",
          sabato: "星期六",
          domenica: "星期日",
          lunedip: "星期一",
          martedip: "星期二",
          mercoledip: "星期三",
          giovedip: "星期四",
          venerdip: "星期五",
          sabatop: "星期六",
          domenicap: "星期日",
          inserisciilcodicedellacard: "输入卡的代码",
          cartanonvalida: "无效的卡",
          lacartaegiaattivasuunaltrotelefonononpuoiattivarlasuquestotelefono:
            "该卡已在另一部手机上激活，您不能在此手机上激活它",
          erroresconosciutodurantelaverificadellacarta: "验证卡时发生未知错误",
          erroredurantelaverificadellacarta: "验证卡时发生错误",
          aggiungiunaltracard: "添加另一张卡",
          acquista: "购买",
          aggiungilatuacfanovisitcard: "添加您的Fano Visit Card",
          attivalafanovisitcard: "激活Fano Visit Card",
          inserisciilcodicechehairicevuto: "输入您收到的激活码",
          scannerizzailqrcode: "扫描QR码",
          scansiona: "扫描",
          invia: "发送",
          errorenellachiamataapi: "API调用错误",
          rispostaapinonvalida: "无效的API响应",
          erroredurantelachiamataallapi: "调用API时出错",
          cardattivataconsuccesso: "卡已成功激活",
          erroredurantelattivazionedellacardriprovapiutardi:
            "激活卡时出错，请稍后重试",
          modifica: "编辑",
          inserisciidatiperlafanovisitcard: "输入Fano Visit Card的数据",
          nome: "名字",
          cognome: "姓",
          provenienza: "来源",
          ladatanonpuoessereinferiorealgiornocorrente: "日期不能早于当前日期",
          seriale: "序列号",
          informativabreve: "简短信息",
          reset: "重置",
          visualizzaletuecard: "查看您的卡",
          inserisciilcodice: "输入激活码",
          oggi: "今天",
          siattiveratra: "将在激活",
          giorni: "天",
          seisicurodivolereliminarequestacarta: "您确定要删除此卡吗？",
          attivo: "激活",
          nonattivo: "未激活",
          fanovisitcard: "Fano Visit Card",
          elimina: "删除",
          serviziinclusicart: "包括的服务",
          giornirimanenti: "剩余天数",
          letuefanovisitcard: "您的Fano Visit Card",
          scadetra: "将于到期：",
          giorniscade: "天",
          scopridipiu: "了解更多",
          acquistafanocard: "购买您的Fano Card",
          sbloccaContenuto: "通过激活或购买您的Fano Visit Card解锁内容",
          durata: "持续时间",
          sceglilatipologia: "选择类型",
          nessunrisultatocorrispondente: "没有与您的搜索匹配的结果",
          chiudicamera: "关闭相机",
          prodottitipici: "典型产品",
          viniecantine: "葡萄酒和酒庄",
          numeriUtili: "实用号码",
          comeArrivare: "如何到达",
          comuneeterritorio: "市镇和领土",
          changeLanguageConfirmationTitle: "更改语言将重新启动应用程序",
          changeLanguageConfirmationText: "您确定要更改语言吗？",
          changeLanguageConfirmationButton: "确认",
          changeLanguageConfirmationCancelButton: "取消",
        },
      },
      fallbackLng: "fr",
      detection: {
        order: [
          "localStorage",
          "querystring",
          "cookie",
          "navigator",
          "htmlTag",
          "path",
          "subdomain",
        ],
        caches: ["localStorage", "cookie"],
      },
    },
  });

const renderReactDom = () => {
  ReactDOM.render(
    <HashRouter>
      <App />
    </HashRouter>,
    document.getElementById("root")
  );
};

// renderReactDom()

if (window.cordova) {
  document.addEventListener(
    "deviceready",
    () => {
      renderReactDom();
      localStorage.setItem("uuid", device.uuid);
      if ("geolocation" in navigator) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
            if (result.state === "granted") {
              console.log("Geolocation permission granted");
            } else if (result.state === "prompt") {
              navigator.geolocation.getCurrentPosition(
                function () {
                  console.log("Geolocation permission granted");
                },
                function () {
                  console.log("Geolocation permission denied");
                }
              );
            } else {
              console.log("Geolocation permission denied");
            }
          });
      } else {
        console.log("Geolocation is not supported by this browser");
      }
    },
    false
  );
} else {
  renderReactDom();

  if ("geolocation" in navigator) {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "granted") {
          console.log("Geolocation permission granted");
        } else if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(
            function () {
              console.log("Geolocation permission granted");
            },
            function () {
              console.log("Geolocation permission denied");
            }
          );
        } else {
          console.log("Geolocation permission denied");
        }
      });
  } else {
    console.log("Geolocation is not supported by this browser");
  }
}
//
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
