import React, { useEffect, useState } from "react";
import fanoCard7gg from "../assets/FanoCard7gg.jpg";
import QRCode from "qrcode.react";
import arrowDown from "../assets/icons/arrowDown.svg";
import fanoCard14gg from "../assets/FanoCard14gg.jpg";
import { useTranslation } from "react-i18next";

export const FanoCard7gg = (props) => {
  const { t } = useTranslation();

  const [dataCard, setDataCard] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [cardHeight, setCardHeight] = useState("0");
  const [cardTop, setCardTop] = useState("[540px]");
  const [cardPaddingTop, setCardPaddingTop] = useState("0");
  const [cardPaddingBottom, setCardPaddingBottom] = useState("0");

  useEffect(() => {
    const filteredData = props.data.filter((item) => item.status === true);
    setDataCard(filteredData);
    console.log(filteredData);
  }, [props.data]);

  const today = new Date();

  const handleCardClick = (card) => {
    setSelectedCard(card);
    toggleCard();
  };

  const closePopup = () => {
    setSelectedCard(null);
  };

  const deleteCard = (precode, tiketid, fanocard) => {
    const updatedData = dataCard.filter(
      (card) => card.tiketid !== selectedCard.tiketid
    );
    setDataCard(updatedData);
    setSelectedCard(null);
    deleteLocalStorage(precode);
    deleteLocalStorage(tiketid);
    deleteLocalStorage(fanocard);

    if (updatedData.length === 0) {
      window.location.reload();
    }
  };

  const deleteLocalStorage = (precode, tiketid, fanocard) => {
    localStorage.removeItem(`qrCode-${precode}`);
    localStorage.removeItem(`qrCode-${tiketid}`);
    localStorage.removeItem(`qrCode-${fanocard}`);
  };

  const filteredDataCard = dataCard.filter((item) => {
    const startDate = new Date(
      item.data_inizio.substring(0, 4),
      item.data_inizio.substring(4, 6) - 1,
      item.data_inizio.substring(6)
    );
    const endDate = new Date(
      item.data_fine.substring(0, 4),
      item.data_fine.substring(4, 6) - 1,
      item.data_fine.substring(6)
    );

    const daysUntilActivation = Math.ceil(
      (startDate - today) / (1000 * 60 * 60 * 24)
    );
    const activationText =
      daysUntilActivation === 0
        ? `${t("oggi")}`
        : `${t("siattiveratra")} ${daysUntilActivation} ${t("giorni")}`;

    if (daysUntilActivation >= 0 && daysUntilActivation <= 30) {
      item.activationText = activationText;
      return item.id_tipologia === "57" || item.id_tipologia === "58";
    }

    return false;
  });

  filteredDataCard.sort((a, b) => {
    const startDateA = new Date(
      a.data_inizio.substring(0, 4),
      a.data_inizio.substring(4, 6) - 1,
      a.data_inizio.substring(6)
    );
    const endDateA = new Date(
      a.data_fine.substring(0, 4),
      a.data_fine.substring(4, 6) - 1,
      a.data_fine.substring(6)
    );
    const isActiveA = today >= startDateA && today <= endDateA;

    const startDateB = new Date(
      b.data_inizio.substring(0, 4),
      b.data_inizio.substring(4, 6) - 1,
      b.data_inizio.substring(6)
    );
    const endDateB = new Date(
      b.data_fine.substring(0, 4),
      b.data_fine.substring(4, 6) - 1,
      b.data_fine.substring(6)
    );
    const isActiveB = today >= startDateB && today <= endDateB;

    if (isActiveA && !isActiveB) {
      return -1;
    }
    if (!isActiveA && isActiveB) {
      return 1;
    }
    return 0;
  });

  function formatDate(dateString) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6);
    return `${day}/${month}/${year}`;
  }

  function toggleCardHeight() {
    setCardHeight(cardHeight === "0" ? "55vh" : "0");
    setCardTop(cardTop === "[580px]" ? "0" : "[580px]");
    setCardPaddingTop(cardPaddingTop === "0" ? "4" : "0");
    setCardPaddingBottom(cardPaddingBottom === "0" ? "4" : "0");
  }

  const toggleCard = () => {
    setIsCardOpen(!isCardOpen);
    toggleCardHeight();
  };

  const confirmDelete = (precode, ticketid, fanocard) => {
    if (window.confirm(`${t("seisicurodivolereliminarequestacarta")}`)) {
      deleteCard(precode, ticketid, fanocard);
    }
  };

  return (
    <div className="card flex flex-wrap justify-center items-center mt-5 overflow-hidden overflow-x-hidden">
      {dataCard.map((item, index) => {
        const startDate = new Date(
          item.data_inizio.substring(0, 4),
          item.data_inizio.substring(4, 6) - 1,
          item.data_inizio.substring(6)
        );
        const endDate = new Date(
          item.data_fine.substring(0, 4),
          item.data_fine.substring(4, 6) - 1,
          item.data_fine.substring(6)
        );
        const isActive = today >= startDate && today <= endDate;
        const statusText = isActive ? `${t("attivo")}` : `${t("nonattivo")}`;
        const statusColor = isActive ? "bg-green-500" : "bg-red-500";
        const cardImage =
          item.id_tipologia === "57" ? fanoCard7gg : fanoCard14gg;

        return (
          <div
            className="mb-5 text-white font-semibold w-full rounded-[10px] relative cursor-pointer"
            key={index}
            onClick={() => handleCardClick(item)}
          >
            <div className="rounded-2xl overflow-hidden backdrop:opacity-5">
              <img
                src={cardImage}
                alt="Sfondo Fano Card"
                className="h-full w-full rounded-[10px] object-cover"
              />
            </div>

            <span className="absolute top-2 right-2 font-semibold bg-monrealeRed px-3 py-1 text-sm rounded-2xl">
              {item.titolare}
            </span>
            <span
              className={`absolute bottom-2 left-2 px-3 py-1 text-sm rounded-2xl ${statusColor}`}
            >
              {statusText}
            </span>
            <span className="absolute bottom-2 right-2 font-semibold bg-monrealeRed px-3 py-1 text-sm rounded-2xl">
              {isActive
                ? `${Math.ceil((endDate - today) / (1000 * 60 * 60 * 24))} ${t(
                    "giornirimanenti"
                  )}`
                : item.activationText}
            </span>
          </div>
        );
      })}

      {selectedCard &&
        (() => {
          const startDate = new Date(
            selectedCard.data_inizio.substring(0, 4),
            selectedCard.data_inizio.substring(4, 6) - 1,
            selectedCard.data_inizio.substring(6)
          );
          const endDate = new Date(
            selectedCard.data_fine.substring(0, 4),
            selectedCard.data_fine.substring(4, 6) - 1,
            selectedCard.data_fine.substring(6)
          );
          const totalDays = Math.ceil(
            (endDate - startDate) / (1000 * 60 * 60 * 24)
          );
          const remainingDays = Math.ceil(
            (endDate - today) / (1000 * 60 * 60 * 24)
          );
          const percentage = ((totalDays - remainingDays) / totalDays) * 100;

          return (
            <div
              className={`${
                isCardOpen
                  ? "backdrop:blur-3xl backdrop-brightness-50 backdrop-filter fixed top-0 left-0 w-full h-full z-[9999] flex justify-center items-center"
                  : "opacity-0"
              } `}
              onClick={toggleCard}
            >
              <div
                className={`${
                  isCardOpen ? "bg-white " : "bg-monrealeRed delay-300"
                } absolute w-full pb-10 bottom-0 overflow-scroll  b-t-l-r-15 pl-5 pr-5 z-[9999]`}
              >
                <div
                  className={`h-${cardHeight} top-${cardTop} z-10 relative duration-300 pt-${cardPaddingTop} pb-${cardPaddingBottom} w-full`}
                >
                  <div className="text-center w-full">
                    <div className="px-1 flex flex-wrap items-center mb-6 mt-3 w-full">
                      <img
                        src={arrowDown}
                        className="ml-1 mr-3 -rotate-90 cursor-pointer"
                        onClick={toggleCard}
                      />
                      <p className="font-semibold">
                        {/*{selectedCard.sottotipo}*/}
                        {t("fanovisitcard")}
                      </p>
                    </div>
                    {selectedCard.precode ? (
                      <div className="flex flex-wrap justify-center items-center w-full">
                        <QRCode value={selectedCard.precode} size={200} />
                        <p className="text-sm w-full text-center mt-2">
                          {selectedCard.precode}
                        </p>
                      </div>
                    ) : selectedCard.tiketid ? (
                      <div className="flex flex-wrap justify-center items-center w-full">
                        <QRCode value={selectedCard.tiketid} size={200} />
                        <p className="text-sm w-full text-center mt-2">
                          {selectedCard.tiketid}
                        </p>
                      </div>
                    ) : selectedCard.fanocard ? (
                      <div className="flex flex-wrap justify-center items-center w-full">
                        <QRCode value={selectedCard.fanocard} size={200} />
                        <p className="text-sm w-full text-center mt-2">
                          {selectedCard.fanocard}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-5 text-center w-full">
                    <div className="w-full">
                      <p>
                        {t("scadetra")}{" "}
                        {Math.max(
                          0,
                          Math.ceil(
                            (new Date(
                              selectedCard.data_fine.substring(0, 4),
                              selectedCard.data_fine.substring(4, 6) - 1,
                              selectedCard.data_fine.substring(6)
                            ) -
                              today) /
                              (1000 * 60 * 60 * 24)
                          )
                        )}{" "}
                        {t("giorniscade")}
                      </p>
                      <div className="flex items-center mt-3 w-full">
                        <div className="w-full h-2 bg-gray-300 rounded-full w-full">
                          <div
                            className={`h-2 bg-monrealeRed rounded-full w-full`}
                            style={{
                              width: `${
                                100 - percentage <= 0 ? 0 : 100 - percentage
                              }%`,
                            }}
                          ></div>
                        </div>
                      </div>
                      <button
                        className={
                          "w-full bg-red-500 mt-5 text-white px-3 py-2 rounded-md font-medium h-[50px] rounded-[10px] w-full"
                        }
                        onClick={() =>
                          confirmDelete(
                            selectedCard.precode,
                            selectedCard.tiketid,
                            selectedCard.fanocard
                          )
                        }
                      >
                        {t("elimina")}
                      </button>
                      <a href={"https://visitfano.info/fanovisitcard/"}>
                        <button
                          className={
                            "w-full bg-monrealeRed mt-5 text-white px-3 py-2 rounded-md font-medium h-[50px] rounded-[10px] w-full"
                          }
                        >
                          {t("serviziinclusicart")}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })()}
    </div>
  );
};
