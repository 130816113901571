import React from "react";
import { BackButton } from "../components/UI/BackButton";
import LanguageBox from "../components/UI/LanguageSwitcher";
import { useTranslation } from "react-i18next";

export const Impostazioni = () => {
  const { t } = useTranslation();
  return (
    <section className="bg-monrealeRed h-screen text-left">
      <div
        className={"flex flex-wrap h-[16vh] content-center relative z-10 p-5"}
      >
        <div className={"flex flex-wrap items-center"}>
          <BackButton sfondo={"false"} />
          <span className={"text-white font-semibold text-[20px] ml-4"}>
            {t("impostazioni")}
          </span>
        </div>
        {/*<img className={'w-[150px] h-auto m-auto'} src={fanoLogo}/>*/}
      </div>
      <LanguageBox />

      <p className={"text-white ml-4"}>Version: 1.0</p>
    </section>
  );
};
