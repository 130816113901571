import React, { useRef, useState, useEffect } from "react";
import { BackButton } from "../components/UI/BackButton";
import LanguageBox from "../components/UI/LanguageSwitcher";
import jsQR from "jsqr";
import plusIcon from "../assets/icons/plus.svg";
import axios from "../components/api";
import { Link } from "react-router-dom";
import "./FanoCard.css";

import arrowDown from "../assets/icons/arrowDown.svg";
import scansiona from "../assets/icons/scansiona.svg";
import fanoCard10gg from "../assets/FanoCard10gg.png";
import { FanoCard7gg } from "./FanoCard7gg";
import { FanoCard14gg } from "./FanoCard14gg";
import FormCartaFisica from "../components/fanocard/formCartaFisica";

export const FanoCardAdd = () => {
  const [qrValue, setQrValue] = useState("");
  const [isQrCode, setIsQrCode] = useState(false);
  const qrInputRef = useRef();
  const videoContainerRef = useRef();

  const [statusSendDateCard, setStatusSendDateCard] = useState(false);

  const [cardAvailable, setCardAvailable] = useState(false);
  const [allCards, setAllCards] = useState([]);
  function getAllQRCodes() {
    const qrCodes = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith("qrCode")) {
        const qrCode = localStorage.getItem(key);
        qrCodes.push(qrCode);
      }
    }
    return qrCodes;
  }
  useEffect(() => {
    const qrCodes = getAllQRCodes();
    if (qrCodes.length > 0) {
      console.log(JSON.parse(qrCodes));
      setCardAvailable(true);
    }

    const cards = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes("qrCode-")) {
        const card = JSON.parse(localStorage.getItem(key));
        cards.push(card);
      }
    }
    setAllCards(cards);
  }, []);

  useEffect(() => {
    console.log("sono qua 1 25");
    const savedCode = localStorage.getItem("qrCode");
    // if (savedCode) {
    //     setQrValue(savedCode);
    //     setIsQrCode(true);
    //     setTimeout(() => {
    //         handleSubmit();
    //     }, 200);
    // }
  }, []);

  const handleInputChange = (e) => {
    setIsQrCode(false);
    const inputValue = e.target.value;
    const codeParts = inputValue.split("#");
    const extractedCode = codeParts[0];
    setQrValue(extractedCode);
  };

  const handleScanQR = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });
      const video = document.createElement("video");
      videoContainerRef.current.appendChild(video);
      video.srcObject = stream;
      video.play();
      const canvas = document.createElement("canvas");
      const canvasContext = canvas.getContext("2d");
      const scanningInterval = setInterval(() => {
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageData = canvasContext.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          if (code) {
            clearInterval(scanningInterval);
            videoContainerRef.current.removeChild(video);
            stream.getTracks().forEach((track) => track.stop());
            setIsQrCode(true);
            setQrValue(code.data);
            console.log("sono qua 2 80");

            localStorage.setItem(
              `qrCode-${code.data.split("#")[0]}`,
              JSON.stringify({
                code: code.data,
              })
            );

            setTimeout(() => {
              handleSubmit();
            }, 200);
          }
        }
      }, 100);
    } catch (error) {
      console.log("Errore durante lo scan QR:", error);
    }

    handleSubmit();
  };

  const handleSubmit = async () => {
    const code = qrValue.trim();
    const qrCodes = getAllQRCodes();
    if (qrCodes.length > 0) {
      console.log(JSON.parse(qrCodes));
      setCardAvailable(true);
    }
    if (code === "") {
      // console.log("Inserisci il codice della card.");
      return;
    }

    const codeParts = code.split("#");
    const extractedCode = codeParts[0];

    try {
      const response = await fetch(
        `https://card.visitfano.info/it/fanocard/checkprecode.json?codice=${encodeURIComponent(
          code
        )}&uuid=test123`
      );

      const data = await response.json();
      console.log(data);
      if (data.status === true && data.response_code === 21) {
        localStorage.setItem("currentCard", code);
        setStatusSendDateCard(true);
      }
      if (data.status === true) {
        const currentDate = new Date();
        const startDate = new Date(data.code_parts.data_inizio);
        const endDate = new Date(data.code_parts.data_fine);

        if (startDate >= currentDate && endDate >= currentDate) {
          console.log("sono qua 3 123");
          localStorage.setItem(
            `qrCode-${data.code.split("#")[0]}`,
            JSON.stringify({
              code: data.code,
              expirationStart: data.code_parts.data_inizio,
              expirationEnd: data.code_parts.data_fine,
            })
          );
          const cards = [];
          for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes("qrCode-")) {
              const card = JSON.parse(localStorage.getItem(key));
              cards.push(card);
            }
          }
          setAllCards(cards);
          // window.location.href = "/#/fano-card/aggiungi";
        } else {
          // La card è scaduta, salvare le informazioni nel local storage come array
          console.log("sono qua 4 133");
          localStorage.setItem(
            `qrCode-${data.code.split("#")[0]}`,
            JSON.stringify({
              code: data.code,
              expirationStart: data.code_parts.data_inizio,
              expirationEnd: data.code_parts.data_fine,
            })
          );
          const cards = [];
          for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes("qrCode-")) {
              const card = JSON.parse(localStorage.getItem(key));
              cards.push(card);
            }
          }
          setAllCards(cards);
          console.log(
            "La tua card è scaduta. Le informazioni sono state salvate."
          );
        }
      } else {
        console.log("La tua card non è valida.");
      }
    } catch (error) {
      console.log("Errore durante la verifica della card:", error);
    }
  };

  //aggiungo il controllo al click visualizzo un div al click lo rimuovo
  const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();
  // console.log(currentLanguage)

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [height, setHeight] = useState("0");
  const [top, setTop] = useState("[540px]");
  const [padding, setPadding] = useState("0");
  const [paddingTop, setPaddingTop] = useState("0");
  const [paddingBottom, setPaddingBottom] = useState("0");

  function toggleHeight() {
    setHeight(height === "0" ? "45vh" : "0");
    setTop(top === "[580px]" ? "0" : "[580px]");
    setPadding(padding === "0" ? "5" : "0");
    setPaddingTop(paddingTop === "0" ? "4" : "0");
    setPaddingBottom(paddingBottom === "0" ? "4" : "0");
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    toggleHeight();
  };

  useEffect(() => {
    const cards = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes("qrCode-")) {
        const card = JSON.parse(localStorage.getItem(key));
        cards.push(card);
      }
    }
    setAllCards(cards);
  }, []);

  console.log(allCards);
  return (
    <section className="bg-monrealeRed h-screen text-left w-full overflow-hidden">
      <div className={"px-5"}>
        {cardAvailable && (
          <div className="flex justify-between items-center">
            <h1 className="text-white text-2xl font-bold">La tua Fano Card</h1>
            {/*vedo la mia card*/}
            <FanoCard7gg data={allCards} />
          </div>
        )}
        {!cardAvailable && (
          <button
            className="px-3 py-2 rounded-md text-monrealeRed font-medium h-[300px] border-4 border-dashed rounded-[10px] w-full"
            onClick={toggleMenu}
          >
            <img
              src={plusIcon}
              alt={"Aggiungi Fano Card"}
              className={"h-6 w-6 mx-auto mb-3"}
            />
            <p className={"text-white"}>Aggiungi la tua Fano Card</p>
          </button>
        )}
      </div>

      <div className="menu h-[20vh] mt-10 overflow-hidden">
        <div
          className={`${
            isMenuOpen ? "" : "hidden"
          }fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-9999999999999999`}
          id="my-modal"
          onClick={toggleMenu}
        ></div>
        <div
          className={`${
            isMenuOpen ? "bg-white " : "bg-monrealeRed delay-300"
          } absolute w-full bottom-0 overflow-hidden  b-t-l-r-15 pl-5 pr-5`}
        >
          <div
            className={`h-${height} top-${top} z-10 relative duration-300 pt-${paddingTop} pb-${paddingBottom}`}
          >
            <div className={"px-1 flex flex-wrap items-center mb-6 mt-3"}>
              <img
                src={arrowDown}
                className={"ml-1 mr-3 -rotate-90 cursor-pointer"}
                onClick={toggleMenu}
              />
              <p className={"font-semibold"}>Attiva la Fano Card</p>
            </div>
            <div className={"px-1 mb-5"}>
              <p className={"text-black break-words"}>
                Inserisci il codice che hai ricevuto
              </p>
            </div>
            <div className={"flex flex-wrap justify-center items-center mb-5"}>
              <input
                type={"text"}
                style={{ backgroundColor: "#D3D3D3", color: "#000000" }}
                placeholder={"Inserisci il codice"}
                className={
                  "text-left w-full h-[50px] px-5 font-semibold text-black placeholder:text-black placeholder:font-normal relative z-10 rounded-[10px] shadow-monrealeShadow"
                }
                value={qrValue}
                onChange={handleInputChange}
                ref={qrInputRef}
              />
            </div>
            <div className={"px-1 mb-5"}>
              <p className={"text-black break-words"}>Scannerizza il QR Code</p>
            </div>
            <div className={"flex flex-wrap justify-center items-center mt-5"}>
              <div
                className="mr-2 fixed z-[99999] object-cover"
                ref={videoContainerRef}
              />
              <button
                className="flex flex-wrap px-3 justify-center py-2 rounded-[10px] text-white bg-monrealeRed font-medium w-full mb-5 text-left px-5 h-[51px] text-[20px] font-roboto_slab font-bold"
                onClick={handleScanQR}
              >
                <img src={scansiona} className={"ml-1 mr-3 "} />
                Scansiona
              </button>
              <button
                className="bg-white px-3 py-2 rounded-md text-monrealeRed font-medium h-[51px] w-[100px]"
                onClick={handleSubmit}
              >
                Invia
              </button>
            </div>

            {statusSendDateCard && (
              <div className="flex flex-wrap justify-center items-center mt-5">
                <FormCartaFisica />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FanoCardAdd;
