import { useEffect, useState } from "react";
import axios from "../api";
import { Link } from "react-router-dom";
import shoppingWhite from "../../assets/icons/shoppingWhite.svg";
import BarLoader from "react-spinners/BarLoader";
import { useMediaQuery } from "react-responsive";

export const ViniECantineCard = () => {
  const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10; // numero di elementi per pagina
  const eventsPerPage = 50; // limite massimo di eventi da recuperare per ogni chiamata all'API
  const [hasMoreData, setHasMoreData] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/vini-e-cantine?page=${currentPage}&pageSize=${pageSize}`
      )
      .then((response) => {
        setData((prevData) => [...prevData, ...response.data.rows]);
        setTotalPages(response.data.pager.total_pages);
        if (currentPage === response.data.pager.total_pages) {
          setHasMoreData(false);
        }
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [currentPage, currentLanguage, pageSize]);

  async function fetchAllEvents() {
    const allEvents = [];
    const response = await axios.get(
      `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/vini-e-cantine?page=1&pageSize=1`
    );
    const totalEvents = response.data.pager.total_items;
    const maxEventsToFetch = Math.min(
      eventsPerPage,
      totalEvents - data?.length
    );
    const maxPagesToFetch = Math.ceil(maxEventsToFetch / pageSize);
    for (let i = 0; i <= maxPagesToFetch; i++) {
      const response = await axios.get(
        `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/vini-e-cantine?page=${i}&pageSize=${pageSize}`
      );
      allEvents.push(...response.data.rows);
    }
    return allEvents;
  }

  useEffect(() => {
    if (hasMoreData && data?.length < eventsPerPage) {
      setIsLoading(true);
      fetchAllEvents().then((allEvents) => {
        setData(allEvents);
        const totalEvents = allEvents?.length;
        setHasMoreData(totalEvents < totalEvents);
        setIsLoading(false);
      });
    }
  }, [data?.length, eventsPerPage, hasMoreData, currentLanguage]);
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  return (
    <div className={"flex flex-wrap overflow-auto h-full pb-5"}>
      {data.map((singleCantina, i) => (
        <div
          key={i}
          className={
            "flex flex-wrap w-1/2 px-1 lg:w-1/3 lg:w-1/3 rounded-[10px] mb-5"
          }
        >
          <Link to={`/vini-e-cantine/${singleCantina.id}`} className={"w-full"}>
            <div
              className={
                "shadow-monrealeShadow rounded-[10px] w-full relative h-full"
              }
            >
              <div
                className={
                  "absolute bg-monrealeMarkerArancione top-0 right-0 p-2 rounded-[10px]"
                }
              >
                {isDesktop ? (
                  <img src={shoppingWhite} className={"w-10 h-10"} />
                ) : (
                  <img src={shoppingWhite} className={"w-6 h-6"} />
                )}
              </div>
              <img
                src={`https://backendstage.monreale.bbsitalia.com${singleCantina.immagine_anteprima}`}
                className={
                  "w-full h-[250px] lg:h-[28rem] object-cover rounded-[10px]"
                }
              />
              <div
                className={
                  "flex flex-wrap justify-center items-center px-4 py-2"
                }
              >
                {isDesktop ? (
                  <h1
                    className={
                      "text-monrealeRed text-[25px] font-roboto_slab font-bold pt-3 pb-3"
                    }
                  >
                    {singleCantina.titolo}
                  </h1>
                ) : (
                  <h1
                    className={
                      "text-monrealeRed text-[15px] font-roboto_slab font-bold pt-3 pb-3"
                    }
                  >
                    {singleCantina.titolo}
                  </h1>
                )}
              </div>
            </div>
          </Link>
        </div>
      ))}
      {isLoading && (
        <div className="flex justify-center items-center h-44">
          <BarLoader color={"#2b6cb0"} size={25} />
        </div>
      )}
    </div>
  );
};
