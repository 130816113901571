import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { ComuneETerritorio } from "./pages/ComuneETerritorio";
import { MappaDellaCitta } from "./pages/MappaDellaCitta";
import { EventiERassegne } from "./pages/EventiERassegne";
import { ItinerariTuristici } from "./pages/ItinerariTuristici";
import { MuseiECultura } from "./pages/MuseiECultura";
import { OspitalitaESapori } from "./pages/OspitalitaESapori";
import { Impostazioni } from "./pages/Impostazioni";
import SearchResults from "./pages/SearchResults";
import { DoveDormire } from "./pages/DoveDormire";
import { DoveDormireDetail } from "./components/doveDormire/DoveDormireDetail";
import { DoveMangiare } from "./pages/DoveMangiare";
import { DoveMangiareDetail } from "./components/doveMangiare/DoveMangiareDetail";
import { Shopping } from "./pages/Shopping";
import { SaporiEShoppingDetail } from "./components/saporiEShopping/SaporiEShoppingDetail";
import { MuseiECulturaDetail } from "./components/museiECultura/MuseiECulturaDetail";
import { ItinerariTuristiciDetail } from "./components/itinerariTuristici/ItinerariTuristiciDetail";
import MyCalendar from "./pages/Calendar";
import { EventiERassegneDetail } from "./components/eventiERassegne/EventiERassegneDetail";
import { FanoCard } from "./pages/FanoCard";
import { FanoCardLista } from "./pages/FanoCardLista";
import PoadcastPlayer from "./pages/PoadcastPlayer";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { FanoCardAdd } from "./pages/FanoCardAdd";
import { EventiList } from "./components/eventiERassegne/EventiList";
import { News } from "./pages/News";
import { NewsCardDetail } from "./components/news/NewsCardDetail";
import { PuntiDiInteresse } from "./pages/PuntiDiInteresse";
import { Servizi } from "./pages/Servizi";
import { ServiziDetail } from "./components/servizi/ServiziDetail";
import { ProdottiTipici } from "./pages/ProdottiTipici";
import { ProdottiTipiciDetail } from "./components/prodottiTipici/ProdottiTipiciDetail";
import { ViniECantine } from "./pages/ViniECantine";
import { ViniECantineDetail } from "./components/viniECantine/ViniECantineDetail";
import { ComeArrivare } from "./pages/ComeArrivare";
import { NumeriUtili } from "./pages/NumeriUtili";
import { PuntiDiInteresseCard } from "./components/puntiDiInteresse/PuntiDiInteresseCard";
import { PuntiDiInteresseDetail } from "./components/puntiDiInteresse/PuntiDiInteresseDetail";
import { ItinerariTuristiciList } from "./components/itinerariTuristici/ItinerariTuristiciList";
import MapComponentAroundMe from "./components/mappaDellaCitta/MapComponentAroundMe";
import { Privacy } from "./pages/Privacy";
import { Crediti } from "./pages/Crediti";
import { Partner } from "./pages/Partner";
import { ScopriMonreale } from "./pages/ScopriMonreale";
import { Contattaci } from "./pages/Contattaci";
import { StabilimentiBalneariDetail } from "./components/stabilimentiBalneari/StabilimentiBalneariDetail";
import FormCartaFisica from "./components/fanocard/formCartaFisica";
import React from "react";
import { FanoCardTest } from "./pages/FanoCardTest";
import VideoPlaylist from "./components/video/VideoPlaylist";
import AudioPlaylist from "./components/audio/AudioPlaylist";
import { ComuneETerritorioDetail } from "./components/comuneETerritorio/ComuneETerritorioDetail";
const queryClient = new QueryClient();

function AppComponent() {
  return (
    <div className="App h-screen">
      <Routes>
        <Route exact index path="/" element={<Home />}></Route>

        <Route exact index path="/#/" element={<Home />}></Route>

        <Route
          exact
          path="/comune-e-territorio"
          element={<ComuneETerritorio />}
        ></Route>
        <Route
          exact
          path="/comune-e-territorio/:id"
          element={<ComuneETerritorioDetail />}
        ></Route>

        <Route
          exact
          path="/mappa-della-citta"
          element={<MappaDellaCitta />}
        ></Route>

        <Route exact path="/eventi-e-rassegne" element={<MyCalendar />}></Route>
        <Route
          exact
          path="/eventi-e-rassegne/:id"
          element={
            <QueryClientProvider client={queryClient}>
              <EventiERassegneDetail />
            </QueryClientProvider>
          }
        ></Route>

        <Route
          exact
          path="/eventi-e-rassegne/lista/:id"
          element={<EventiList />}
        ></Route>

        <Route
          exact
          path="/itinerari-turistici"
          element={<ItinerariTuristici />}
        ></Route>
        <Route
          exact
          path="/itinerari-turistici/:id"
          element={<ItinerariTuristiciDetail />}
        ></Route>

        <Route
          exact
          path="/musei-e-cultura"
          element={<MuseiECultura />}
        ></Route>

        <Route
          exact
          path="/musei-e-cultura/:id"
          element={<MuseiECulturaDetail />}
        ></Route>

        <Route exact path="/news" element={<News />}></Route>

        <Route exact path="/news/:id" element={<NewsCardDetail />}></Route>

        <Route
          exact
          path="/ospitalita-e-sapori"
          element={<OspitalitaESapori />}
        ></Route>

        <Route exact path="/dove-dormire" element={<DoveDormire />}></Route>
        <Route
          exact
          path="/dove-dormire/:id"
          element={<DoveDormireDetail />}
        ></Route>

        <Route exact path="/dove-mangiare" element={<DoveMangiare />}></Route>
        <Route
          exact
          path="/dove-mangiare/:id"
          element={<DoveMangiareDetail />}
        ></Route>

        <Route exact path="/sapori-e-shopping" element={<Shopping />}></Route>
        <Route
          exact
          path="/sapori-e-shopping/:id"
          element={<SaporiEShoppingDetail />}
        ></Route>

        <Route exact path="/impostazioni" element={<Impostazioni />}></Route>

        <Route exact path="/cerca" element={<SearchResults />}></Route>

        <Route exact path="/calendario" element={<EventiERassegne />}></Route>

        <Route exact path="/fano-visit-card" element={<FanoCardTest />}></Route>
        <Route
          exact
          path="/playlist-video/:id"
          element={<VideoPlaylist />}
        ></Route>
        <Route
          exact
          path="/playlist-audio/:id"
          element={<AudioPlaylist />}
        ></Route>
        {/*<Route exact path="/fano-card-test" element={*/}
        {/*    <FanoCard />*/}
        {/*}>*/}
        {/*</Route>*/}
        <Route
          exact
          path="/fano-card/aggiungi"
          element={<FormCartaFisica />}
        ></Route>

        <Route
          exact
          path="/fano-card/lista"
          element={<FanoCardLista />}
        ></Route>
        <Route exact path="/poadcast" element={<PoadcastPlayer />}></Route>

        <Route
          exact
          path="/punti-di-interesse"
          element={<PuntiDiInteresse />}
        ></Route>

        <Route
          exact
          path="/punti-di-interesse/:id"
          element={<PuntiDiInteresseDetail />}
        ></Route>

        <Route
          exact
          path="/stabilimenti-balneari/:id"
          element={<StabilimentiBalneariDetail />}
        ></Route>

        <Route
          exact
          path="/itinerari-turistici/lista/:id"
          element={<ItinerariTuristiciList />}
        ></Route>
        <Route exact path="/servizi" element={<Servizi />}></Route>
        <Route exact path="/servizi/:id" element={<ServiziDetail />}></Route>
        <Route exact path="/numeri-utili" element={<NumeriUtili />}></Route>
        <Route exact path="/come-arrivare" element={<ComeArrivare />}></Route>
        <Route
          exact
          path="/mappa-around-me"
          element={<MapComponentAroundMe />}
        ></Route>

        <Route exact path="/privacy" element={<Privacy />}></Route>
        <Route exact path="/crediti" element={<Crediti />}></Route>
        <Route exact path="/partner" element={<Partner />}></Route>
        <Route
          exact
          path="/comune-e-territorio"
          element={<ComuneETerritorio />}
        ></Route>
        <Route
          exact
          path="/prodotti-tipici"
          element={<ProdottiTipici />}
        ></Route>
        <Route
          exact
          path="/prodotti-tipici/:id"
          element={<ProdottiTipiciDetail />}
        ></Route>

        <Route exact path="/vini-e-cantine" element={<ViniECantine />}></Route>
        <Route
          exact
          path="/vini-e-cantine/:id"
          element={<ViniECantineDetail />}
        ></Route>

        <Route
          exact
          path="/scopri-monreale"
          element={<ScopriMonreale />}
        ></Route>

        <Route exact path="/contattaci" element={<Contattaci />}></Route>
      </Routes>
    </div>
  );
}

export default AppComponent;
