import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSwitcher.css";
import { useNavigate } from "react-router-dom";

function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const languages = [
    { code: "it", name: "Italiano" },
    { code: "en", name: "English" },
    { code: "es", name: "Español" },
    { code: "fr", name: "Français" },
    { code: "de", name: "Deutsch" },
    { code: "zh-Hans", name: "中文" },
  ];

  function handleLanguageChange(code) {
    setSelectedLanguage(code);
    setShowConfirmation(true);
  }

  function handleConfirm() {
    i18n.changeLanguage(selectedLanguage);
    setShowConfirmation(false);
    navigate("/");
  }

  function handleCancel() {
    setSelectedLanguage(i18n.language);
    setShowConfirmation(false);
  }

  return (
    <div className="flex flex-wrap relative p-5">
      <span className="text-white font-semibold mb-4">{t("language")}</span>
      {languages.map((lang) => (
        <label
          key={lang.code}
          className="block w-full h-10 relative flex items-center"
        >
          <input
            type="radio"
            name="language"
            className="h-5 w-5 text-gray-600"
            checked={selectedLanguage === lang.code}
            onChange={() => handleLanguageChange(lang.code)}
          />
          <span className="ml-2 text-gray-700 text-white">{lang.name}</span>
        </label>
      ))}
      {showConfirmation && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="bg-black opacity-60 absolute w-full min-h-screen z-0" />
            <div className="bg-white rounded-lg shadow-lg p-5 mx-2 relative z-10">
              <h2 className="text-lg font-medium mb-4">
                {t("changeLanguageConfirmationTitle")}
              </h2>
              <p className="mb-4">{t("changeLanguageConfirmationText")}</p>

              <div className="">
                <button
                  className="bg-monrealeRed text-white font-medium px-4 py-2 rounded-6px w-full mb-5"
                  onClick={handleConfirm}
                >
                  {t("changeLanguageConfirmationButton")}
                </button>
                <button
                  className="text-black font-medium px-4 py-2 rounded-6px w-full"
                  onClick={handleCancel}
                >
                  {t("changeLanguageConfirmationCancelButton")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LanguageSwitcher;
