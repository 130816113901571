/* import { t } from "i18next"; */
import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import VisibileConMonrealeCard from "./VisibileConMonrealeCard";
import { AudioPlayer } from "../audio/AudioPlayer";

const Banner = ({
  latitudine,
  longitudine,
  fano_card_audio,
  fano_card_video,
}) => {
  const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();
  const [banner, setBanner] = useState([]);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [audioPlaylist, setaudioPlaylist] = useState([]);
  const [videoPlaylist, setVideoPlaylist] = useState([]);
  const [video360Playlist, setVideo360Playlist] = useState([]);
  const [videoOpen, setVideoOpen] = useState(false);
  const videoRef = useRef(null);

  // filter valid video

  const validVideo = useMemo(() => {
    return videoPlaylist.filter((item) => {
      return item?.video?.length > 0;
    });
  }, [videoPlaylist]);

  const validVideo360 = useMemo(() => {
    return video360Playlist.filter((item) => {
      return item?.video360?.length > 0;
    });
  }, [video360Playlist]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/menu-banner`
        );
        if (response.data?.length > 0) {
          setBanner(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, []);

  // fetch video data
  useEffect(() => {
    const fetchVideoData = async (singleVideoId) => {
      console.log("singleVideoId", singleVideoId);
      try {
        const response = await axios.get(
          `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/media/video/${
            singleVideoId || fano_card_video
          }`
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const fetchVideo360Data = async (singleVideoId) => {
      try {
        const response = await axios.get(
          `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/media/video360/${
            singleVideoId || fano_card_video
          }`
        );
        return response.data;
      } catch (error) {
        return response.data;
      }
    };

    if (fano_card_video?.length > 0) {
      const fetchAllVideoData = async () => {
        const promises = fano_card_video.map(async (videoUrl) => {
          console.log("videoUrl", videoUrl);
          const response = await fetchVideoData(videoUrl);
          return response;
        });

        const allResponses = await Promise.all(promises);
        // flat array of video
        const flatVideo = allResponses.reduce(
          (acc, val) => acc.concat(val),
          []
        );

        // filter unique video
        const uniqueVideo = flatVideo.filter(
          (video, index, self) =>
            index === self.findIndex((t) => t.mid_export === video.mid_export)
        );
        console.log("uniqueVideo", uniqueVideo);

        setVideoPlaylist(uniqueVideo);
      };

      const fetchAllVideo360Data = async () => {
        const promises = fano_card_video.map((videoUrl) =>
          fetchVideo360Data(videoUrl)
        );
        const allResponses = await Promise.all(promises);
        const validResponses = allResponses.filter(
          (response) => response !== null && response !== undefined
        );

        // flat array of video
        const flatVideo = validResponses.reduce(
          (acc, val) => acc.concat(val),
          []
        );
        // filter unique video
        const uniqueVideo = flatVideo.filter(
          (video, index, self) =>
            index === self.findIndex((t) => t.mid_export === video.mid_export)
        );

        setVideo360Playlist(uniqueVideo);
      };

      fetchAllVideoData();
      fetchAllVideo360Data();
    }
  }, [fano_card_video]);

  // fetch audio data
  useEffect(() => {
    const fetchAudioData = async (audioUrl) => {
      try {
        const response = await axios.get(
          `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/media/audio/${audioUrl}`
        );
        return response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    };

    if (fano_card_audio?.length > 0) {
      const fetchAllAudioData = async () => {
        const promises = fano_card_audio.map((audioUrl) =>
          fetchAudioData(audioUrl)
        );
        const allResponses = await Promise.all(promises);
        const validResponses = allResponses.filter(
          (response) => response !== null
        );
        // flat array of audio
        const flatAudio = validResponses.reduce(
          (acc, val) => acc.concat(val),
          []
        );
        // filter unique audio
        const uniqueAudio = flatAudio.filter(
          (audio, index, self) =>
            index === self.findIndex((t) => t.mid_export === audio.mid_export)
        );

        setaudioPlaylist(uniqueAudio);
      };

      fetchAllAudioData();
    }
  }, [fano_card_audio]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCurrentPosition([position.coords.latitude, position.coords.longitude]);
    });
  }, [navigator]);

  const handleCloseFullscreen = (e) => {
    e.stopPropagation();
    setVideoOpen(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <div className="w-full h-[100px] bg-white bg-opacity-80 fixed bottom-0 left-0 z-[9998] border-t-2 border-grey-100">
      {banner.length > 0 && (
        <div
          className="flex flex-wrap justify-around
       items-center h-full"
        >
          {audioPlaylist?.length > 0 && (
            <>
              {audioPlaylist.length === 1 ? (
                <div className="flex flex-col justify-center items-center ">
                  <VisibileConMonrealeCard
                    customClass={"rounded-[10px]"}
                    customStyle={{
                      maxWidth: 40,
                      height: 40,
                    }}
                    withFanoCard={parseInt(
                      audioPlaylist[0]?.visibile_con_fanocard
                    )}
                    smallIcon={true}
                  >
                    <AudioPlayer
                      openedFromBanner={true}
                      audioUrl={`https://backendstage.monreale.bbsitalia.com/${audioPlaylist[0]?.audio}`}
                      titolo={audioPlaylist[0]?.titolo}
                    />
                    <div
                      className="relative w-10 h-10 flex justify-center items-center bg-monrealeRed rounded-[10px]
                      "
                    >
                      <img
                        src={`https://backendstage.monreale.bbsitalia.com${banner[0].icona}`}
                        className={"w-6 h-6"}
                        alt={banner[0].titolo}
                      />
                    </div>
                    <p className="font-bold text-sm">{banner[0].titolo}</p>
                  </VisibileConMonrealeCard>
                </div>
              ) : (
                <Link
                  className="flex flex-col justify-center items-center"
                  to={`/playlist-audio/${fano_card_audio || 0}`}
                  state={{ audioPlaylist }}
                >
                  <div
                    className=" w-10 h-10 flex justify-center items-center bg-monrealeRed rounded-[10px]
          "
                  >
                    <img
                      src={`https://backendstage.monreale.bbsitalia.com${banner[0].icona}`}
                      className={"w-6 h-6"}
                      alt={banner[0].titolo}
                    />
                  </div>

                  <p
                    className="font-bold text-sm"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    {banner[0].titolo}
                  </p>
                </Link>
              )}
            </>
          )}
          {validVideo?.length + validVideo360.length > 0 && (
            <>
              {validVideo?.length + validVideo360.length === 1 ? (
                <div className="flex flex-col justify-center items-center">
                  <VisibileConMonrealeCard
                    customClass={"rounded-[10px]"}
                    customStyle={{
                      maxWidth: 40,
                      height: 40,
                    }}
                    withFanoCard={
                      validVideo.length > 0
                        ? parseInt(validVideo[0]?.visibile_con_fanocard)
                        : parseInt(validVideo360[0]?.visibile_con_fanocard)
                    }
                    smallIcon={true}
                  >
                    <div
                      className=" w-10 h-10 flex justify-center items-center bg-monrealeRed rounded-[10px]
          "
                      onClick={(e) => {
                        setVideoOpen(!videoOpen);
                      }}
                    >
                      {videoOpen && (
                        <>
                          <div
                            style={{
                              position: "fixed",
                              top: "48px",
                              left: "24px",
                              cursor: "pointer",
                              width: "48px",
                              height: "48px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#5392AB",
                              borderRadius: "50%",
                              zIndex: 22,
                            }}
                            onClick={handleCloseFullscreen}
                          >
                            <p
                              style={{
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "32px",
                              }}
                            >
                              &#x2715;
                            </p>
                          </div>
                          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-20">
                            <video
                              ref={videoRef}
                              className="w-full h-full"
                              controls
                              autoPlay
                              preload="auto"
                              style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100vw",
                                height: "100vh",
                                padding: "12px",
                                paddingTop: "20px",
                                backgroundColor: "black",
                                zIndex: 9999,
                              }}
                              src={
                                validVideo.length > 0
                                  ? `https://backendstage.monreale.bbsitalia.com/${validVideo[0]?.video}`
                                  : `https://backendstage.monreale.bbsitalia.com/${validVideo360[0]?.video}`
                              }
                            />
                          </div>
                        </>
                      )}
                      <img
                        src={`https://backendstage.monreale.bbsitalia.com${banner[1].icona}`}
                        className={"w-6 h-6"}
                        alt={banner[1].titolo}
                      />
                    </div>

                    <p
                      className="font-bold text-sm"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      {banner[1].titolo}
                    </p>
                  </VisibileConMonrealeCard>
                </div>
              ) : (
                <Link
                  className="flex flex-col justify-center items-center"
                  to={`/playlist-video/${fano_card_video}`}
                  state={{ videoPlaylist, video360Playlist }}
                >
                  <div
                    className=" w-10 h-10 flex justify-center items-center bg-monrealeRed rounded-[10px]
          "
                  >
                    <img
                      src={`https://backendstage.monreale.bbsitalia.com${banner[1].icona}`}
                      className={"w-6 h-6"}
                      alt={banner[1].titolo}
                    />
                  </div>

                  <p className="font-bold text-sm">{banner[1].titolo}</p>
                </Link>
              )}
            </>
          )}

          {(!!latitudine || !!longitudine) && (
            <a
              className="flex flex-col justify-center items-center"
              target="_blank"
              href={`https://maps.apple.com/?saddr=${currentPosition}&daddr=${latitudine},${longitudine}`}
            >
              <div
                className=" w-10 h-10 flex justify-center items-center bg-monrealeRed rounded-[10px]
          "
              >
                <img
                  src={`https://backendstage.monreale.bbsitalia.com${banner[2].icona}`}
                  className={"w-6 h-6"}
                  alt={banner[2].titolo}
                />
              </div>
              <p className="font-bold text-sm">{banner[2].titolo}</p>
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default Banner;
