import React, { useState, useEffect } from "react";
import { BackButton } from "../components/UI/BackButton";
import { Link } from "react-router-dom";
import plusIcon from "../assets/icons/plus.svg";
import sfondoCard from "../assets/comune-di-fano.jpg";
import list from "../assets/icons/list.png";
import { FanoCardAdd } from "./FanoCardAdd";

export const FanoCard = () => {
  const savedCode = localStorage.getItem("qrCode");
  const savedExpirationTime = localStorage.getItem("qrCodeExpirationTimeEnd");
  const [isValid, setIsValid] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);

  useEffect(() => {
    if (savedCode && savedExpirationTime) {
      const expirationDate = new Date(
        savedExpirationTime.slice(0, 4),
        savedExpirationTime.slice(4, 6) - 1,
        savedExpirationTime.slice(6, 8)
      );
      const currentDate = new Date();
      if (currentDate <= expirationDate) {
        setIsValid(true);
        setRemainingTime(calculateRemainingTime(currentDate, expirationDate));
      }
    }
  }, [savedCode, savedExpirationTime]);

  const calculateRemainingTime = (currentDate, expirationDate) => {
    const diff = expirationDate.getTime() - currentDate.getTime();
    if (diff < 0) return null;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    return { days, hours };
  };

  return (
    <section className="bg-monrealeRed h-screen text-left w-full overflow-hidden">
      {/*<div className={"flex flex-wrap h-[16vh] content-center relative z-10 p-5"}>*/}
      {/*    <div className={"flex flex-wrap items-center"}>*/}
      {/*        <BackButton sfondo={"false"} />*/}
      {/*        <span className={"text-white font-semibold text-[20px] ml-4"}>Fano Card</span>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div
        className={
          "flex flex-wrap h-[16vh] content-center relative z-0 p-5 bg-monrealeRed overflow-hidden"
        }
      >
        <div className={"flex flex-wrap items-center relative w-full"}>
          <BackButton sfondo={"true"} />
          <span className={"text-white font-semibold text-[20px] ml-4"}>
            Le tue Fano Cards
          </span>
        </div>
      </div>
      {/*<div className={"px-1"}>*/}
      {/*<p className={"text-white ml-4 break-words"}>*/}
      {/*    Qui puoi vedere la tua Fano Card o aggiungila se non ne hai ancora una.*/}
      {/*</p>*/}
      {/*</div>*/}
      <div
        className={
          "flex flex-wrap justify-center items-center mt-5 overflow-hidden"
        }
      >
        {isValid && savedCode && (
          <div
            className={
              "text-white font-semibold h-[175px] w-full rounded-[10px] relative"
            }
          >
            <img
              src={sfondoCard}
              alt={"Sfondo Fano Card"}
              className={"h-full w-full rounded-[10px] object-cover"}
            />
            <div
              className={
                "absolute top-0 left-0 h-full w-full rounded-[10px] bg-black bg-opacity-20"
              }
            />
            <p>Codice: {savedCode}</p>
            <p>Scadenza: {savedExpirationTime}</p>
            {remainingTime && (
              <p>
                Tempo rimanente: {remainingTime.days} giorni{" "}
                {remainingTime.hours} ore
              </p>
            )}
          </div>
        )}
        {!isValid && (
          // <button
          //     className="px-3 py-2 rounded-md text-monrealeRed font-medium h-[300px] border-4 border-dashed rounded-[10px] w-full ml-2 mt-5"
          // >
          //     <Link to={"/fano-card/aggiungi"} className={"text-white"}>
          //         <img src={plusIcon} alt={"Aggiungi Fano Card"} className={"h-6 w-6 mx-auto mb-3"} />
          //         <p>Aggiungi la tua Fano Card</p>
          //     </Link>
          // </button>
          <FanoCardAdd />
        )}
      </div>
    </section>
  );
};
