const estrapolaHref = (htmlString) => {
  // Creiamo un elemento temporaneo (div) per analizzare la stringa HTML
  var tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  // Cerchiamo l'elemento <a> all'interno del div temporaneo
  var linkElement = tempElement.querySelector("a");

  // Se è presente un elemento <a>, estraiamo l'attributo href
  if (linkElement) {
    return linkElement.getAttribute("href");
  } else {
    return null; // Se non c'è alcun link, ritorniamo null
  }
};

export const openExternalLink = (url, event) => {
  // extract the url if string includes href
  const href = estrapolaHref(url);
  if (window.cordova) {
    event.preventDefault();
    if (!!href) {
      console.log("Opening external link in system browser HREF estrapolato: " + href);
      window.cordova.InAppBrowser.open(href, "_system");
    } else {
      console.log("Opening external link in system browser HREF normale: " + href);
      window.cordova.InAppBrowser.open(url, "_system");
    }
  } else {
    event.preventDefault();
    if (!!href) {
      window.open(href, "_blank");
    } else {
      window.open(url, "_blank");
    }
  }
};
