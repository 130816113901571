import axios from "axios";
import { useEffect, useState } from "react";
import InEvidenzaCarousel from "./InEvidenzaCarousel";
import ScopriMonrealeCarousel from "./ScopriMonrealeCarousel";

const ScopriMonreale = () => {
  return (
    <div className={"h-[59vh]"}>
      <ScopriMonrealeCarousel />
    </div>
  );
};

export default ScopriMonreale;
