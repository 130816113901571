import Logo from "../../assets/logo.png";
import HamburgerMenu from "./HamburgerMenu";
import { Link } from "react-router-dom";
import search from "../../assets/icons/search.svg";
const Header = () => {
  return (
    <div
      className={
        "bg-monrealeRed flex flex-wrap h-[16vh] content-center items-end p-8"
      }
    >
      <div className={"flex flex-wrap"}>
        <HamburgerMenu />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <img className={"w-[48px] h-auto"} src={Logo} />
        <div
          style={{
            textAlign: "left",
            marginTop: "8px",
          }}
        >
          <p className="text-white font-semibold text-sm mb-0">
            Comune di Monreale{" "}
          </p>
          <p className="text-white text-xs mb-0">
            Città Metropolitana di Palermo
          </p>
        </div>
      </div>
      <div className={"flex flex-wrap"}>
        <Link to={"/cerca"}>
          <img src={search} alt={"search"} className={"mb-2"} />
        </Link>
      </div>
    </div>
  );
};

export default Header;
