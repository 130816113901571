import { useEffect, useState } from "react";
import lockOpen from "../../assets/icons/lock_open.svg";
import lockClose from "../../assets/icons/lock_close.svg";
import { Link } from "react-router-dom";
import { t } from "i18next";

const VisibileConMonrealeCard = ({
  children,
  rounded,
  customClass,
  customStyle,
  withFanoCard,
  smallIcon,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [QRCodeValid, setQRCodeValid] = useState(false);

  //// controllo esistenza e validità della Fano Card
  const checkLocalStorageForQRKeys = () => {
    // controllo se almeno una Fano Card esiste nel local storage
    const keys = Object.keys(localStorage);
    const qrKeys = keys.filter((key) => key.startsWith("qrCode-"));
    if (qrKeys.length > 0) {
      // Se ci sono elementi nel localStorage con una chiave che inizia con "qrCode-", controlla se sono validi
      checkQRCodeValidity(qrKeys);
    }
  };
  const checkQRCodeValidity = (qrKeys) => {
    // Per ogni elemento nel localStorage con una chiave che inizia con "qrCode-"
    qrKeys.forEach((key) => {
      // Prendi il valore dell'elemento
      const fanoCard = localStorage.getItem(key);
      // Converto il valore in JSON
      const fanoCardObj = JSON.parse(fanoCard);
      // controllo se la data di oggi rientra nella validità della Fano Card
      const today = new Date();
      const todayDate = today.getDate();
      // aggiungi 0 per numeri < 10
      const fixedTodayDate = ("0" + todayDate).slice(-2);
      const todayMonth = today.getMonth() + 1;
      // aggiungi 0 per numeri < 10
      const fixedTodayMonth = todayMonth < 10 ? "0" + todayMonth : todayMonth;
      const todayYear = today.getFullYear();
      const todayString = `${todayYear}${fixedTodayMonth}${fixedTodayDate}`;

      // Se il valore è valido, imposta lo stato del QR code su true
      if (
        !!fanoCardObj.status &&
        fanoCardObj.data_inizio <= todayString &&
        fanoCardObj.data_fine >= todayString
      ) {
        setQRCodeValid(true);
      }
    });
  };
  // Esegui la funzione per controllare il localStorage
  useEffect(() => {
    checkLocalStorageForQRKeys();
  }, []);

  /////

  const handleOpensPopup = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsPopupOpen(true);
  };

  return (
    <div className="relative display-none w-full h-full">
      {isPopupOpen && <BuyCardPopup setIsPopupOpen={setIsPopupOpen} />}
      {!QRCodeValid && !!withFanoCard && (
        <div
          onClick={(e) => handleOpensPopup(e)}
          className={`absolute top-0 left-0 w-full h-full bg-black opacity-70 z-20 ${
            !!rounded ? rounded : ""
          } ${customClass && customClass}`}
          style={customStyle && customStyle}
        ></div>
      )}
      {!!withFanoCard && (
        <div
          onClick={(e) => !QRCodeValid && handleOpensPopup(e)}
          className={
            smallIcon
              ? `absolute top-0 left-0 ${
                  !!QRCodeValid
                    ? "bg-[#55AE3A] z-10"
                    : "bg-monrealeMarkerRosso z-20"
                } text-white font-bold text-[9px] px-1 py-1 rounded-[5px] `
              : `absolute top-0 left-0 ${
                  !!QRCodeValid
                    ? "bg-[#55AE3A] z-10"
                    : "bg-monrealeMarkerRosso z-20"
                } text-white font-bold text-[9px] px-2 py-1 rounded-[5px] `
          }
        >
          {!QRCodeValid ? (
            <img
              src={lockClose}
              className={smallIcon ? "w-3 h-3 " : "w-6 h-6 "}
            />
          ) : (
            <img
              src={lockOpen}
              className={smallIcon ? "w-3 h-3 " : "w-5 h-6 "}
            />
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default VisibileConMonrealeCard;

const BuyCardPopup = ({ setIsPopupOpen }) => {
  const handleClosePopup = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsPopupOpen(false);
  };
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[99999] px-4 flex justify-center items-center ">
      <div className="bg-white rounded-lg px-5 pt-2 pb-5">
        <div className="flex flex-wrap justify-end">
          <button
            onClick={(e) => handleClosePopup(e)}
            className="text-xl font-bold"
          >
            X
          </button>
        </div>
        {/*      <div className="flex flex-wrap justify-center items-center">
            
          <img src={fanoCard} className="w-40 h-40" />
        </div> */}
        <div className="flex flex-wrap justify-center items-center mt-2">
          <h1 className="text-2xl font-bold">{t("acquistafanocard")}</h1>
        </div>
        <div className="flex flex-wrap justify-center items-center mt-2">
          <p>{t("sbloccaContenuto")}</p>
        </div>
        <div className="flex flex-wrap justify-center items-center mt-4">
          <Link
            to={"https://card.visitfano.info/"}
            className="bg-monrealeRed text-white px-4 py-2 rounded-lg font-semibold"
          >
            {t("scopridipiu")}
          </Link>
        </div>
      </div>
    </div>
  );
};
