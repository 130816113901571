import InEvidenzaCarouselTotem from "./InEvidenzaCarouselTotem";

const InEvidenzaTotem = () => {
    return (
        <div className={'h-[59vh]'}>
                <InEvidenzaCarouselTotem/>
        </div>
    )
}

export default InEvidenzaTotem