import React, { useRef, useState, useEffect } from 'react';
import { PlayIcon } from "@heroicons/react/20/solid";
import { PauseIcon } from "@heroicons/react/20/solid";
import { ChevronDoubleLeftIcon } from "@heroicons/react/20/solid";
import { ChevronDoubleRightIcon  } from "@heroicons/react/20/solid";

const PoadcastInterface  = ({ src, tracks, onSkipBackward, onSkipForward }) => {
    const audioRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

    useEffect(() => {
        audioRef.current.addEventListener('timeupdate', updateProgress);
        audioRef.current.addEventListener('loadedmetadata', updateDuration);
        return () => {
            audioRef.current.removeEventListener('timeupdate', updateProgress);
            audioRef.current.removeEventListener('loadedmetadata', updateDuration);
        };
    }, []);

    const updateProgress = () => {
        setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
        setCurrentTime(audioRef.current.currentTime);
    };

    const updateDuration = () => {
        setDuration(audioRef.current.duration);
    };

    const playPauseAudio = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleSkipBackward = () => {
        setProgress(0);
        setCurrentTime(0);
        const newIndex = currentTrackIndex > 0 ? currentTrackIndex - 1 : 0;
        setCurrentTrackIndex(newIndex);
    };

    const handleSkipForward = () => {
        setProgress(0);
        setCurrentTime(0);
        const newIndex = currentTrackIndex < tracks.length - 1 ? currentTrackIndex + 1 : currentTrackIndex;
        setCurrentTrackIndex(newIndex);
    };

    const handleTimeSliderChange = (event) => {
        const newTime = event.target.value;
        setProgress(newTime);
        audioRef.current.currentTime = (newTime / 100) * audioRef.current.duration;
        setCurrentTime(audioRef.current.currentTime);
    };

    useEffect(() => {
        if (audioRef.current) {
            setIsPlaying(false);
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            audioRef.current.src = tracks[currentTrackIndex].url;
        }
    }, [currentTrackIndex]);
    return (
        <div className="bg-gray-100 p-4 rounded shadow-md">
            <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold">{tracks[currentTrackIndex].title}</h2>
            </div>
            <div className="flex items-center justify-center mt-4 space-x-4">
                <button onClick={handleSkipBackward}>
                    <ChevronDoubleLeftIcon className="w-6 h-6 text-blue-500" />
                </button>
                <button onClick={playPauseAudio}>
                    {isPlaying ? (
                        <PauseIcon className="w-6 h-6 text-blue-500" />
                    ) : (
                        <PlayIcon className="w-6 h-6 text-blue-500" />
                    )}
                </button>
                <button onClick={handleSkipForward}>
                    <ChevronDoubleRightIcon className="w-6 h-6 text-blue-500" />
                </button>
            </div>
            <div className="relative w-full h-1 bg-gray-300 mt-4">
                <input
                    type="range"
                    min="0"
                    max="100"
                    value={progress}
                    className="absolute top-0 left-0 h-1 bg-blue-500"
                    style={{ width: '100%', height: '100%', zIndex: 1 }}
                    onChange={handleTimeSliderChange}
                />
                <div
                    className="absolute top-0 left-0 h-1 bg-gray-300"
                    style={{ width: '100%', height: '100%', zIndex: 0 }}
                ></div>
            </div>
            <div className="flex justify-between text-sm mt-1">
                <span>{formatTime(currentTime)}</span>
                <span>{formatTime(duration)}</span>
            </div>
            <audio
                ref={audioRef}
                src={tracks[currentTrackIndex].url}
                onEnded={handleSkipForward}
                className="hidden"
            />
        </div>
    );
};

export default PoadcastInterface;