import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
// import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
// import 'leaflet-defaulticon-compatibility';
import axios from "axios";
import { MappaDellaCittaCard } from "./MappaDellaCittaCard";
import filterIcon from "../../assets/icons/filter.svg";
import { BackButtonWhite } from "../UI/BackButtonWhite";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import itinerariIcon from "../../assets/icons/itinerariTuristiciWhite.svg";

import markerRosso from "../../assets/icons/marker_rosso.png";
import markerBlu from "../../assets/icons/marker_blu.png";
import markerArancione from "../../assets/icons/marker_arancione.png";
import markerAzzurro from "../../assets/icons/marker_azzurro.png";
import { Link } from "react-router-dom";
const ToggleButtons = ({ options, selectedOption, onChange }) => (
  <div className="flex flex-wrap basis-1/2 w-full">
    {options.map((option) => (
      <button
        key={option.value}
        onClick={() => onChange(option.value)}
        className={`${
          option.value === selectedOption
            ? "bg-monrealeRed text-white shadow-monrealeShadow"
            : "bg-white text-monrealeRed border-monrealeRed border-2"
        } rounded-md px-4 py-2 mb-2 w-[47%] mr-2`}
      >
        {option.label}
      </button>
    ))}
  </div>
);

const MapComponent = () => {
  const { t } = useTranslation();

  const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();

  const [locations, setLocations] = useState([]);
  const [filter, setFilter] = useState("all");
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(
          `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/locations`
        );
        // setLocations(response.data);
        const order = [
          "musei_e_cultura",
          "punti_di_interesse",
          "eventi",
          // "stabilimenti_balneari",
        ];

        setLocations(
          response.data
            .filter(
              (location) =>
                location.field_togli_da_mappa_export === "0" ||
                location.field_togli_da_mappa_export === null
            )
            .sort((a, b) => {
              const typeAIndex = order.indexOf(a.type);
              const typeBIndex = order.indexOf(b.type);
              if (typeAIndex === -1 && typeBIndex === -1) {
                return 0;
              } else if (typeAIndex === -1) {
                return 1;
              } else if (typeBIndex === -1) {
                return -1;
              }
              return typeAIndex - typeBIndex;
            })
        );

        console.log(locations);
      } catch (error) {}
    };
    fetchLocations();
  }, []);

  const getMarkerIcon = (type) => {
    switch (type) {
      case "musei_e_cultura":
        return L.icon({
          iconUrl: markerRosso,
          // iconAnchor: [15, 30],
          popupAnchor: [7, 0],
        });
      case "stabilimenti_balneari":
      case "dove_dormire":
        return L.icon({
          iconUrl: markerAzzurro,
          // iconAnchor: [15, 30],
          popupAnchor: [7, 0],
        });
      case "punti_di_interesse":
        return L.icon({
          iconUrl: markerBlu,
          // iconAnchor: [15, 30],
          popupAnchor: [7, 0],
        });
      case "eventi":
        return L.icon({
          iconUrl: markerBlu,
          // iconAnchor: [15, 30],
          popupAnchor: [7, 0],
        });
      default:
        return L.icon({
          iconUrl: markerArancione,
          // iconAnchor: [15, 30],
          popupAnchor: [7, 0],
        });
    }
  };

  const filteredLocations = locations.filter(
    (location) =>
      (filter === "all" || location.type === filter) &&
      location.latitude != null &&
      location.longitude != null
  );

  useEffect(() => {
    if (mapRef.current) {
      let newBounds;
      if (filteredLocations?.length > 0) {
        let minLat = filteredLocations[0].latitude;
        let maxLat = filteredLocations[0].latitude;
        let minLng = filteredLocations[0].longitude;
        let maxLng = filteredLocations[0].longitude;
        for (let i = 1; i < filteredLocations?.length; i++) {
          const { latitude, longitude } = filteredLocations[i];
          if (latitude < minLat) {
            minLat = latitude;
          } else if (latitude > maxLat) {
            maxLat = latitude;
          }
          if (longitude < minLng) {
            minLng = longitude;
          } else if (longitude > maxLng) {
            maxLng = longitude;
          }
        }
        newBounds = L.latLngBounds([minLat, minLng], [maxLat, maxLng]);
        console.log("Fit to filtered locations:", filteredLocations);
        mapRef.current.fitBounds(newBounds);
      } else {
        newBounds = L.latLngBounds([38.0812, 13.2894], [38.0812, 13.2894]);
        console.log("Fit to Fano");
        mapRef.current.fitBounds(newBounds, { padding: [50, 50], maxZoom: 15 });
      }
    }
  }, [filteredLocations]);

  const getDetailLink = (location) => {
    if (!location || !location.type || !location.id) {
      return null;
    }
    let link = null;
    switch (location.type) {
      case "eventi":
        link = `/eventi-e-rassegne/${location.id}`;
        break;
      case "musei_e_cultura":
        link = `/musei-e-cultura/${location.id}`;
        break;
      case "punti_di_interesse":
        link = `/punti-di-interesse/${location.id}`;
        break;
      case "dove_mangiare":
        link = `/dove-mangiare/${location.id}`;
        break;
      case "dove_dormire":
        link = `/dove-dormire/${location.id}`;
        break;
      case "sapori_e_shopping":
        link = `/sapori-e-shopping/${location.id}`;
        break;
      case "stabilimenti_balneari":
        link = `/stabilimenti-balneari/${location.id}`;
        break;
      case "servizi":
        link = `/servizi/${location.id}`;
        break;
      case "comune_e_territorio":
        link = `/comune-e-territorio/${location.id}`;
        break;
      default:
        link = `/dettaglio/${location.id}`;
        break;
    }

    return link;
  };

  const filterOptions = [
    { value: "all", label: `${t("tutte")}` },
    { value: "eventi", label: `${t("eventi")}` },
    { value: "musei_e_cultura", label: `${t("museiecultura")}` },
    /*     { value: "punti_di_interesse", label: `${t("puntidiinteresse")}` }, */
    { value: "dove_mangiare", label: `${t("dovemangiare")}` },
    { value: "dove_dormire", label: `${t("dovedormire")}` },
    /*     { value: "sapori_e_shopping", label: `${t("saporieshopping")}` },
    { value: "stabilimenti_balneari", label: `${t("stabilimentibalneari")}` }, */
  ];
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const getTypeBackground = (location) => {
    if (!location || !location.type || !location.id) {
      return null;
    }
    let src = null;
    switch (location.type) {
      case "eventi":
        src = "bg-monrealeMarkerBlu";
        break;
      case "rassegna":
        src = "bg-monrealeMarkerBlu";
        break;
      case "news":
        src = "bg-monrealeMarkerBlu";
        break;
      case "musei_e_cultura":
        src = "bg-monrealeMarkerRosso";
        break;
      case "punti_di_interesse":
        src = "bg-monrealeMarkerBlu";
        break;
      case "stabilimenti_balneari":
        src = "bg-monrealeMarkerAzzurro";
        break;
      case "dove_mangiare":
        src = "bg-monrealeMarkerArancione";
        break;
      case "dove_dormire":
        src = "bg-monrealeMarkerAzzurro";
        break;
      case "sapori_e_shopping":
        src = "bg-monrealeMarkerArancione";
        break;
      case "percorsi_turistici":
        src = "bg-monrealeMarkerBlu";
        break;
    }

    return src;
  };

  return (
    <div className="h-100vh">
      <div
        className={`${
          showFilterModal ? "block" : "hidden"
        } fixed z-[9999] inset-0 overflow-y-auto`}
      >
        <div className="flex items-end justify-end flex-wrap min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div
            className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full w-full bottom-0"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            style={{
              position: "absolute",
              bottom: "100px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {t("selezionaunacategoria")}
              </h3>
              <div className="mt-4">
                <ToggleButtons
                  options={filterOptions}
                  selectedOption={filter}
                  onChange={(val) => setFilter(val)}
                />
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={() => setShowFilterModal(false)}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-monrealeRed text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {t("chiudi")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <MapContainer
        center={[38.0812, 13.2894]}
        zoom={12}
        style={{ height: "45vh", width: "100%", zIndex: 0 }}
        ref={mapRef}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {filteredLocations.map((location) => (
          <Marker
            key={`${location.id}-${location.type}`}
            position={[location.latitude, location.longitude]}
            icon={getMarkerIcon(location.type)}
          >
            <Popup>
              <div className={"text-center relative"}>
                <h3 className={"font-bold text-lg mb-0"}>{location.titolo}</h3>
                <span
                  className={"m-2 block capitalize"}
                  style={{ margin: "0px!important" }}
                >
                  {location.type.replaceAll("_", " ")}
                </span>
                <span
                  className={"m-2 block capitalize"}
                  style={{ margin: "0px!important" }}
                >
                  {location.dove}
                </span>
                <Link
                  to={getDetailLink(location)}
                  className={`${getTypeBackground(
                    location
                  )} text-white rounded-md px-4 py-2 relative text-white block`}
                >
                  {/*<img src={itinerariIcon} className={"w-6 h-6 inline-block mr-3"}/>*/}
                  <span
                    className={"inline-block m-0 text-white"}
                    style={{ margin: "0px!important" }}
                  >
                    {t("vaiallascheda")}
                  </span>
                </Link>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      <button
        onClick={() => setShowFilterModal(true)}
        className=" mt-1 mb-5 text-left bg-white text-white rounded-md px-4 py-2 mb-2 flex justify-center items-center"
      >
        <img src={filterIcon} alt="Filtro" className="w-10 h-10 mr-4" />

        <span
          className={"text-monrealeRed font-roboto_slab text-[20px] font-bold"}
        >
          {t("filtrapercategoria")}
        </span>
      </button>
      {/*<MappaDellaCittaCard data={filteredLocations}/>*/}

      {filteredLocations?.length === 0 && (
        <div>{t("nessunrisultatotrovato")}.</div>
      )}

      <MappaDellaCittaCard data={filteredLocations} filter={filter} />
      {isDesktop ? (
        <div
          className={
            "bg-monrealeRed h-[10vh] b-t-l-r-15 content-center flex flex-wrap items-center p-8"
          }
        >
          <BackButtonWhite sfondo={"true"} />
        </div>
      ) : (
        <div className={"hidden"} />
      )}
    </div>
  );
};

export default MapComponent;
