import React, { useState } from "react";

const GalleryItems = (props) => {
  const [isLastElement, setIsLastElement] = useState(false);

  return (
    <>
      {props.src === null ? (
        ""
      ) : (
        <a
          href={`https://backendstage.monreale.bbsitalia.com/${props.src}`}
          key={"Gallery-" + props.i}
          target="_blank"
          rel="noreferrer"
          data-cropped="true"
        >
          <div
            className={`relative overflow-hidden shadow-lg cursor-pointer rounded-2xl ${
              isLastElement ? "mr-8" : ""
            }`}
            key={props.i}
          >
            <img
              className={`object-cover w-full h-48 shrink-0 w-80 h-40 rounded-lg shadow-xl bg-white`}
              src={`https://backendstage.monreale.bbsitalia.com/${props.src}`}
              alt=""
            />
          </div>
        </a>
      )}
    </>
  );
};

export default GalleryItems;
