import MapComponent from "../components/mappaDellaCitta/MapComponent";
import {BackButton} from "../components/UI/BackButton";
import {MappaDellaCittaCard} from "../components/mappaDellaCitta/MappaDellaCittaCard";
import {useEffect, useState} from "react";

export const MappaDellaCitta = () => {

    // const [totem, setTotem] = useState(false)

    // useEffect(() => {
    //     if(process.env.REACT_FANO === 'true'){
    //         setTotem(true);
    //
    //     } else{
    //         setTotem(false);
    //     }
    // }, [])
    // console.log(totem)


    return (
        <>
            <div className={'flex flex-wrap content-center z-[1000] p-5 top-24 left-0 absolute lg:hidden'}>
                <BackButton sfondo={'true'}/>
            </div>
            <MapComponent/>
        </>
    )
}



