import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import axios from "../api";
import { Link } from "react-router-dom";
import doveDormireWhite from "../../assets/icons/itinerari_tursitici_white.svg";
import BarLoader from "react-spinners/BarLoader";
import { useMediaQuery } from "react-responsive";
import { BackButton } from "../UI/BackButton";
import { BackButtonWhite } from "../UI/BackButtonWhite";
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import markerBlu from "../../assets/icons/marker_blu.png";
import markerRosso from "../../assets/icons/marker_rosso.png";
import markerAzzurro from "../../assets/icons/marker_azzurro.png";
import markerArancione from "../../assets/icons/marker_arancione.png";
import { useTranslation } from "react-i18next";
import VisibileConMonrealeCard from "../UI/VisibileConMonrealeCard";

export const ItinerariTuristiciList = ({ eventi }) => {
  const { id } = useParams();

  const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10; // numero di elementi per pagina
  const eventsPerPage = 50; // limite massimo di eventi da recuperare per ogni chiamata all'API
  const [hasMoreData, setHasMoreData] = useState(true);
  const [locations, setLocations] = useState([]);

  const [titoloRassegna, setTitoloRassegna] = useState("");
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `https://backendstage.monreale.bbsitalia.com/${currentLanguage}/api/correlati/luoghi/${id}`
      )
      .then((response) => {
        console.log(response.data);

        setData(response.data);
        setTitoloRassegna(response.data[0].titolo_itinerari);
        setLocations(response.data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const mapRef = useRef();

  useEffect(() => {
    if (mapRef.current) {
      let newBounds;
      if (locations?.length > 0) {
        const filteredLocations = locations.filter(
          (location) =>
            location.latitude !== null && location.longitude !== null
        );
        if (filteredLocations?.length > 0) {
          let minLat = filteredLocations[0].latitude;
          let maxLat = filteredLocations[0].latitude;
          let minLng = filteredLocations[0].longitude;
          let maxLng = filteredLocations[0].longitude;
          for (let i = 1; i < filteredLocations?.length; i++) {
            const { latitude, longitude } = filteredLocations[i];
            if (latitude < minLat) {
              minLat = latitude;
            } else if (latitude > maxLat) {
              maxLat = latitude;
            }
            if (longitude < minLng) {
              minLng = longitude;
            } else if (longitude > maxLng) {
              maxLng = longitude;
            }
          }
          newBounds = L.latLngBounds([minLat, minLng], [maxLat, maxLng]);
          console.log("Fit to filtered locations:", filteredLocations);
          mapRef.current.fitBounds(newBounds);
        } else {
          newBounds = L.latLngBounds([38.0812, 13.2894], [38.0812, 13.2894]);
          console.log("Fit to Fano");
          mapRef.current.fitBounds(newBounds, {
            padding: [50, 50],
            maxZoom: 15,
          });
        }
      } else {
        newBounds = L.latLngBounds([38.0812, 13.2894], [38.0812, 13.2894]);
        console.log("Fit to Fano");
        mapRef.current.fitBounds(newBounds, { padding: [50, 50], maxZoom: 15 });
      }
    }
  }, [locations]);

  console.log(data);

  const getDetailLink = (location) => {
    if (!location || !location.type || !location.id) {
      return null;
    }
    let link = null;
    switch (location.type) {
      case "eventi":
        link = `/eventi-e-rassegne/${location.id}`;
        break;
      case "musei_e_cultura":
        link = `/musei-e-cultura/${location.id}`;
        break;
      case "punti_di_interesse":
        link = `/punti-di-interesse/${location.id}`;
        break;
      case "dove_mangiare":
        link = `/dove-mangiare/${location.id}`;
        break;
      case "dove_dormire":
        link = `/dove-dormire/${location.id}`;
        break;
      case "sapori_e_shopping":
        link = `/sapori-e-shopping/${location.id}`;
        break;
      default:
        link = `/dettaglio/${location.id}`;
        break;
    }

    return link;
  };

  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const getMarkerIcon = (type) => {
    switch (type) {
      case "punti_di_interesse":
        return L.icon({
          iconUrl: markerBlu,
          // iconAnchor: [15, 30],
          popupAnchor: [7, 0],
        });
    }
  };

  const filteredLocations = locations.filter(
    (location) => location.latitude !== null && location.longitude !== null
  );

  const { t } = useTranslation();

  return (
    <div className={"overflow-auto h-full"}>
      <div
        className={
          "flex flex-wrap h-[16vh] content-center relative z-10 p-5 bg-monrealeRed w-full"
        }
      >
        <div className={"flex flex-wrap items-center"}>
          {!isDesktop && <BackButton sfondo={"false"} />}
          {isDesktop ? (
            <span className={"text-white font-semibold text-[40px] ml-4"}>
              {titoloRassegna}
            </span>
          ) : (
            <span className={"text-white font-semibold text-[20px] ml-4"}>
              {titoloRassegna}
            </span>
          )}
        </div>
      </div>
      <MapContainer
        center={[38.0812, 13.2894]}
        zoom={12}
        className={"lg:h-[74vh] h-[84vh]"}
        style={{ width: "100%" }}
        ref={mapRef}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {filteredLocations.map((location) => (
          <Marker
            key={location.id}
            position={[location.latitude, location.longitude]}
            icon={getMarkerIcon("punti_di_interesse")}
          >
            <Popup>
              <div className={"text-center relative"}>
                <h3 className={"font-bold text-lg mb-0"}>{location.titolo}</h3>
                <span
                  className={"m-2 block capitalize"}
                  style={{ margin: "0px!important" }}
                >
                  {location.type.replaceAll("_", " ")}
                </span>
                <span
                  className={"m-2 block capitalize"}
                  style={{ margin: "0px!important" }}
                >
                  {location.dove}
                </span>
                <Link
                  to={`/punti-di-interesse/${location.id}`}
                  className={`bg-monrealeMarkerBlu text-white rounded-md px-4 py-2 relative text-white block`}
                >
                  {/*<img src={itinerariIcon} className={"w-6 h-6 inline-block mr-3"}/>*/}
                  <span
                    className={"inline-block m-0 text-white"}
                    style={{ margin: "0px!important" }}
                  >
                    {t("vaiallascheda")}
                  </span>
                </Link>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      <div
        className={
          "lg:h-[41vh] lg:bottom-40 w-full flex overflow-auto pb-5 snap-x overflow-x-auto absolute bottom-0 z-[9999]"
        }
      >
        {data?.map((doveDormireList, i) => (
          <div
            key={i}
            className={
              "flex w-[25vh] lg:flex-wrap lg:basis-1/3 lg:basis-1/3 rounded-[10px] p-2 mb-5 snap-center shrink-0 first:pl-4 last:pr-4"
            }
          >
            <VisibileConMonrealeCard
              rounded={"rounded-[10px]"}
              withFanoCard={parseInt(doveDormireList.visibile_con_fanocard)}
            >
              <Link to={getDetailLink(doveDormireList)} className={"w-full"}>
                <div
                  className={
                    "shadow-monrealeShadow rounded-[10px] w-full relative h-full bg-white"
                  }
                >
                  <div
                    className={
                      "absolute bg-monrealeRed top-0 right-0 p-2 rounded-[10px]"
                    }
                  >
                    {isDesktop ? (
                      <img src={doveDormireWhite} className={"w-10 h-10"} />
                    ) : (
                      <img src={doveDormireWhite} className={"w-6 h-6"} />
                    )}
                  </div>
                  <img
                    src={`https://backendstage.monreale.bbsitalia.com${doveDormireList.immagine_anteprima}`}
                    className={
                      "w-full h-[125px] lg:h-[28rem] object-cover rounded-[10px]"
                    }
                  />
                  <div
                    className={
                      "flex flex-wrap justify-center items-center px-4 py-2"
                    }
                  >
                    {isDesktop ? (
                      <h1
                        className={
                          "text-monrealeRed text-[25px] font-roboto_slab font-bold pt-3 pb-3"
                        }
                      >
                        {doveDormireList.titolo}
                      </h1>
                    ) : (
                      <h1
                        className={
                          "text-monrealeRed text-[15px] font-roboto_slab font-bold pt-3 pb-3"
                        }
                      >
                        {doveDormireList.titolo}
                      </h1>
                    )}
                  </div>
                </div>
              </Link>
            </VisibileConMonrealeCard>
          </div>
        ))}
      </div>
      {isDesktop ? (
        <div
          className={
            "bg-monrealeRed h-[10vh] b-t-l-r-15 content-center flex flex-wrap items-center p-8"
          }
        >
          <BackButtonWhite sfondo={"true"} />
        </div>
      ) : (
        <div className={"hidden"} />
      )}
      {isLoading && (
        <div className="flex justify-center items-center h-44">
          <BarLoader color={"#2b6cb0"} size={25} />
        </div>
      )}
    </div>
  );
};
